import React, { useState,useEffect } from "react";

import './zona3ligexternas.css';

import livro from '../../../../images/svg/livro.svg';
import chapeu_finalista from '../../../../images/svg/chapeu_finalista.svg';
import facebook from '../../../../images/svg/facebook.svg';
import instagram from '../../../../images/svg/instagram.svg';
import youtube from '../../../../images/svg/youtube.svg';


import api,{apiEndpoint,mseErroComunicacao} from "../../../../services/api";
import Loading from "../../../../components/Loading";

import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
export default function Zona3LigExternas(){
    const navigate= useNavigate
    const [loading,setLoading]=useState(true);
    const [data,setData]=useState([]);
    useEffect(()=>{
        async function loadData() {

            //const response = 
            await api.get('ligacoes/all/home-ligacoes').then(response=>{
                //debugger;
                //console.log(response.data)
                if (response.data.length > 0) {
                    //tentou aceder com um id que nao existe, navegue para a home
                    setData(response.data);
                }
                setLoading(false);
            }).catch(err=>{
                //toast.error(mseErroComunicacao);
                setTimeout(function(){
                    navigate("/erro-comunicacao");
                },5000);
            });
            
        }

        loadData();
    },[])

    if(loading===true){
        return(
<section>
    <div className="container">
        <div className="row ligacao justify-content-center">
            <div className="col-12">
                <Loading/>
            </div>
        </div>
    </div>
</section>
        );

    }


    return(
<section>
    <div className="container main-title-home">
        <div className="row ligacao justify-content-center">
            <div className="col-12">
                <h1>Ligações Externas</h1>
                <div className="row justify-content-center">
                    <div className="col-2">
                        <hr/>
                    </div>    
                </div>
            </div>
        </div>
    </div>
    <div className="container"  align="center">
       
        <div className="row ligacoes justify-content-center" style={{flexWrap:"wrap"}}>
        {data.length > 0 && (

                        <div className="col-12 col-md-6 link-card">
                            <div href="#" className="card card1 card-link rounded">
                                <a href={data[0].link} target="blank">
                                    <div className="card-body">
                                        <img src={livro} className="color"
                                            style={{ height: "80px", width: "80px", padding: "10px" }} alt="icon1" />
                                        <h3 style={{ marginTop: "1rem" }}>{data[0].title}</h3>
                                    </div>
                                </a>
                            </div>
                        </div>
                    )
        }
        {data.length > 1 && (

                        <div className="col-12 col-md-6 link-card">
                            <div href="#" className="card card2 card-link rounded">
                                <a href={data[1].link} target="blank">
                                    <div className="card-body" >
                                        <img src={chapeu_finalista} className="color"
                                            style={{ height: "80px", width: "80px", padding: "10px" }} alt="icon2" />
                                        <h3 style={{ marginTop: "1rem" }}>{data[1].title}</h3>
                                    </div>
                                </a>
                            </div>
                        </div>
                    )
        }
        {/*{data.length > 2 && (  
            <div className="col-6 col-md-4 link-card-small">
                <div href="#" className="card card2 card-link rounded">
                    <a href={data[2].link} target="blank">
                        <div className="card-body" >
                            <img src={chapeu_finalista} className="color" 
                            style={{height: "80px", width: "80px", padding:"10px"}} alt="icon2" />
                            <h3 style={{marginTop: "1rem"}}>{data[2].title}</h3>
                        </div>
                    </a>    
                </div>
            </div>    
        )}
        {data.length > 3 && (  
                <div className="col-6 col-md-4 link-card-small">
                <div className="card card3 card-link rounded">  
                    <a href={data[3].link} target="blank">
                        <div className="card-body" style={{marginTop: "60px"}}>
                            <img src={facebook} className="color" style={{height: "70px", width: "70px",
                             padding:"10px"}} alt="icon3" />
                            <h3 style={{marginTop: "1rem"}}>{data[3].title}</h3>
                        </div>
                    </a>    
                </div> 
            </div>  
            )}
            {data.length > 4 && (  
            <div className="col-6 col-md-4 link-card-small">
                <div className="card card4 card-link rounded">
                    <a className={data[3].link} target="blank">
                        <div className="card-body" style={{marginTop: "60px"}}>
                            <img src={instagram} className="color" style={{height: "70px", width:"70px", padding:"10px"}}
                             alt="icon4" />
                            <h3 style={{marginTop: "1rem"}}>{data[4].title}</h3>
                        </div>
                    </a>    
                </div>
            </div>
            )}

            {data.length > 5 && (   
            <div className="col-6 col-md-4 link-card-small">
                <div className="card card5 card-link rounded">
                    <a href={data[4].link}  target="blank">
                        <div className="card-body" style={{marginTop: "60px"}}>
                            <img src={youtube} className="color" style={{height: "70px", width: "70px", padding:"10px"}} alt="icon5" />
                            <h3 style={{marginTop: "1rem"}}>{data[3].title} </h3>
                        </div>
                    </a>    
                </div>
            </div>
            )}
            */}
        </div>
    </div>
</section>
    );
};