import React,{useState} from 'react';


import { useLocation } from 'react-router-dom';

import {FaInstagramSquare,FaFacebookSquare,FaTwitterSquare,FaPinterestSquare,FaEmail} from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import styles from '../../styles'  
import {
    InstapaperShareButton,
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    TwitterShareButton,
    WhatsappShareButton
  } from "react-share";


  import {siteURLShare} from '../../services/api'



  import img_partilhar from '../../images/svg/new_share.svg'
  import { BtnPartilhar } from '../../styles';

  export default function Share(props){
    
    const location=useLocation();    

    function setColorOver(id){
      document.getElementById(id).style.color="#1ABFD5"
    }
    function setColorOut(id){
      document.getElementById(id).style.color="#737373";
    }

    function slideToggle(id,option=null){
      //alert(id)
      if(option===null){
        var _status=document.getElementById(id).getAttribute("data-status")
        //alert(_status)
        if(_status==="closed"){
          document.getElementById(id).setAttribute("data-status","opened")
          document.getElementById(id).style.height="170px"
          document.getElementById(id).style.top="-170px"
        }else{
          document.getElementById(id).setAttribute("data-status","closed")
          document.getElementById(id).style.height="0px"
        }

      }else if(option==="open"){
        document.getElementById(id).setAttribute("data-status","opened")
          document.getElementById(id).style.height="170px"
          document.getElementById(id).style.top="-170px"
      }else if(option==="close"){
        document.getElementById(id).setAttribute("data-status","closed")
          document.getElementById(id).style.height="0px"
      }
       
      
    }
    return(
      <>
      <div style={{position:"relative",width:"40px",height:"40px",overflow:"visible"}}>

      <div id="slide" data-status="closed" /*onMouseLeave={(e)=>{slideToggle("slide","close")}}*/ style={{backgroundColor:"white",position:"absolute",width:"42px",height:"0px",left:"3px",overflow:'hidden'}}>         
            <FacebookShareButton url={siteURLShare+location.pathname} onMouseOver={(e)=>{setColorOver("icon2");}} onMouseOut={(e)=>{setColorOut("icon2");}}>
              <FaFacebookSquare id="icon2" style={{ color:"#737373", fontSize: "2em" }}></FaFacebookSquare>                
            </FacebookShareButton><br/>

            <TwitterShareButton url={siteURLShare+location.pathname} onMouseOver={(e)=>{setColorOver("icon3");}} onMouseOut={(e)=>{setColorOut("icon3");}}>
             <FaTwitterSquare id="icon3" style={{ color:"#737373", fontSize: "2em"}}></FaTwitterSquare> 
            </TwitterShareButton><br/>

            <PinterestShareButton media={props.media} url={siteURLShare+location.pathname} onMouseOver={(e)=>{setColorOver("icon4");}} onMouseOut={(e)=>{setColorOut("icon4");}}>
             <FaPinterestSquare id="icon4" style={{ color:"#737373", fontSize: "2em" }}></FaPinterestSquare> 
            </PinterestShareButton><br/>

            <EmailShareButton url={siteURLShare+location.pathname}  onMouseOver={(e)=>{setColorOver("icon5");}} onMouseOut={(e)=>{setColorOut("icon5");}}>
             <MdEmail id="icon5" style={{ color:"#737373", fontSize: "2.2em",marginLeft:"-1px",marginTop:"-4px"}}></MdEmail> 
            </EmailShareButton>
          </div>
          <BtnPartilhar style={{position:"absolute",left:"0px",bottom:"0px"}} 
                      onClick={(e)=>{slideToggle("slide")}}
                      /*onMouseEnter={(e)=>{slideToggle("slide","open")}}*/
                      
                     ><img  src={img_partilhar} width="10" height="10"></img></BtnPartilhar>
          </div>
      </>  
    );   
  }