import React, { useContext, useEffect, useState } from "react";

import { useParams, useNavigate, useLocation } from "react-router-dom";

import BreadCrumbs from "../../../components/BreadCrumbs";
import NextPrevLine from "../../../components/NextPrevLine";

import Loading from "../../../components/Loading";
import "./noticiadetalhe.css";
import img_exames from "../../../images/avaliacao/exames.jpg";
import api, {
  apiEndpoint,
  siteURL,
  siteURLShare,
  mseErroComunicacao,
} from "../../../services/api";

import Erro from "../../../pages/Erro";

import { Helmet } from "react-helmet-async";

import Share from "../../../components/Share";
import ListaDeLinks from "../../../components/ListadeLinks";

import image_default_anuncios from "../../../images/images_default/placeholder_noticias_309x470.jpg";
import image_default_concursos from "../../../images/images_default/placeholder_concursos_309x470.jpg";
import image_default_noticias from "../../../images/images_default/placeholder_noticias_309x470.jpg";
import { toast } from "react-toastify";

import RedirectedContext, {
  RedirectContextProvider,
} from "../../../contexts/global";

//import FavoriteContext from "../Store/favorites-context";
export default function NoticiaAnuncioDetalhe() {
  const contexto = useContext(RedirectedContext);
  const location = useLocation();
  const { slug } = useParams();
  const navigate = useNavigate();
  const [breadCrumbsData, setBreadCrumbsData] = useState([
    {
      nome: "Home",
      url: "/",
    },
    {
      nome: "Notícias e Anúncios",
      url: "/noticia-anuncio",
    },
  ]);

  const [nextPrevData, setNextPrevData] = useState({
    prevName: null,
    prevUrl: null,
    nextName: null,
    nextUrl: null,
  });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error404, setError404] = useState(false);
  const [listaLinks, setListaLinks] = useState([]);
  const [imageDefault, setImageDefault] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    //console.log("PATHNAME",location.pathname)
    async function loadData() {
      let apiEndpoint = `/pagina/noticia-anuncio/${slug}`;
      if (location.pathname !== null) {
        //console.log("PATHNAME",location.pathname)
        if (location.pathname.startsWith("/concurso/")) {
          apiEndpoint = `/pagina/concurso/${slug}`;
        }
      }
      //const response =
      await api
        .get(apiEndpoint)
        .then((response) => {
          if (
            typeof response.data == "object" &&
            response.data.status_code === 404
          ) {
            setError404(true);
          } else if (response.data.length > 0) {
            //1 NextPrevLink
            setData(response.data[1]);
            setListaLinks(response.data[2]);
            setNextPrevData({
              prevName: response.data[0].previous_name,
              prevUrl: response.data[0].previous_url,
              nextName: response.data[0].next_name,
              nextUrl: response.data[0].next_url,
            });

            //2
            if (response.data[1].type === "noticia") {
              setImageDefault(image_default_noticias);
              setBreadCrumbsData([
                {
                  nome: "Home",
                  url: "/",
                },
                {
                  nome: "Notícias e Anúncios",
                  url: "/noticia-anuncio",
                },
                {
                  nome: response.data[1].title,
                  url: response.data[1].url,
                },
              ]);
            } else if (response.data[1].type === "anuncio") {
              setImageDefault(image_default_anuncios);
              setBreadCrumbsData([
                {
                  nome: "Home",
                  url: "/",
                },
                {
                  nome: "Notícias e Anúncios",
                  url: "/noticia-anuncio",
                },
                {
                  nome: response.data[1].title,
                  url: response.data[1].url,
                },
              ]);
            } else if (response.data[1].type === "concurso") {
              setImageDefault(image_default_concursos);
              setBreadCrumbsData([
                {
                  nome: "Home",
                  url: "/",
                },
                {
                  nome: "Notícias e Anúncios",
                  url: "/noticia-anuncio",
                },
                {
                  nome: "Concursos",
                  url: "/concursos",
                },
                {
                  nome: response.data[1].title,
                  url: response.data[1].url,
                },
              ]);
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          //toast.error(mseErroComunicacao);
          setTimeout(function () {
            navigate("/erro-comunicacao");
          }, 5000);
        });
    }

    loadData();

    //console.log(data)
    // window.scrollTo(0, 0)
  }, [slug]);

  if (error404 === true) {
    return (
      <>
        <Erro></Erro>
      </>
    );
  }

  function tratadata(data) {
    var options = { day: "numeric", month: "long", year: "numeric" };
    var date = new Date(data);
    let data_str = date
      .toLocaleDateString("pt-pt", options)
      .toString()
      .replace(".", "");
    options = { month: "long" };
    var mes1 = date
      .toLocaleDateString("pt-pt", options)
      .toString()
      .replace(".", "");
    var mes2 = mes1.charAt(0).toUpperCase() + mes1.slice(1);
    //console.log("mes2=",mes2)

    data_str = data_str.replace(mes1, mes2);
    return data_str;
  }
  return (
    <section className="content">
      {loading === true && <Loading></Loading>}

      {loading === false && (
        <Helmet>
          <title>
            {`Agrupamento de Escolas de Monte da Ola ${">"} ${data.title}`}{" "}
          </title>

          <meta
            name="keywords"
            content={`Website Agrupamento de Escolas de Monte da Ola, Notícias e Anúncios (${data.type}) > ${data.title}`}
          />
          {/*facebook*/}
          <meta name="og:title" content={data.title} />
          <meta name="og:url" content={siteURLShare + location.pathname} />
          <meta
            name="og:description"
            content={`${data.body.replace(/<[^>]+>/g, "").substring(0, 200)}`}
          />

          <meta name="og:type" content="website" />
          {/*twitter*/}
          <meta
            name="twitter:card"
            content={`Agrupamento de Escolas de Monte da Ola ${">"} Projectos Integradores (${
              data.type
            }) > ${data.title}`}
          />
          <meta name="twitter:title" content={data.title} />
          <meta
            name="twitter:description"
            content={`${data.body.replace(/<[^>]+>/g, "").substring(0, 200)}`}
          />
          <meta
            name="twitter:site"
            content={siteURLShare + location.pathname}
          />

          <meta
            name="description"
            content={`${data.body.replace(/<[^>]+>/g, "").substring(0, 200)}`}
          />
          <meta
            name="twitter:description"
            content={`${data.body.replace(/<[^>]+>/g, "").substring(0, 200)}`}
          />

          {data.image !== null && (
            <meta name="twitter:image" content={data.image} />
          )}

          {data.image !== null && <meta name="og:image" content={data.image} />}

          {data.image === null && (
            <meta name="twitter:image" content={imageDefault} />
          )}

          {data.image === null && (
            <meta name="og:image" content={imageDefault} />
          )}
          <meta
            name="twitter:creator"
            content="Agrupamento de Escolas de Monte da Ola"
          />
        </Helmet>
      )}

      {loading === false && (
        <>
          <div className="container detalhe">
            <BreadCrumbs data={breadCrumbsData} />
          </div>
          {/* Titulo */}
          <div className="container text-align-left">
            <div className="row ligacao justify-content-left" >
              <div className="col-12">
                <h5 className="type" style={{marginTop:'3.5rem'}}>
                  {data.type === "noticia"
                    ? "Notícia"
                    : data.type === "informacao"
                    ? "Informação"
                    : data.type === "concurso"
                    ? "Concurso"
                    : ""}
                </h5>
                <h1 className="main-title" >{data.title}</h1>
                <p>
                  {tratadata(data.publishDate)} - {data.author}
                </p>
                <div className="row justify-content-left">
                  <div className="col-2">
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* corpo detalhe */}
          <div className="container detalhe">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-12 col-md-8 col-lg-8 artigo-info">
                {/*corpo de texto do item inicio*/}
                <div dangerouslySetInnerHTML={{ __html: data.body }}></div>
                <ListaDeLinks listaLinks={listaLinks}></ListaDeLinks>
                <div style={{ float: "right" }}>
                  <Share
                    id="share"
                    media={data.image}
                    style={{ float: "right" }}
                  ></Share>
                </div>

                {/*corpo de texto do item fim*
                <button className="btn btn-share" type="button"></button>    */}
              </div>
              {/*img do item */}
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 noticia">
                <div className="image-artigo">
                  {data.image !== null && (
                    <img
                      src={data.image}
                      className="d-block w-100"
                      alt={data.title}
                    />
                  )}

                  {data.image === null && (
                    <img
                      src={imageDefault}
                      className="d-block w-100"
                      alt={data.title}
                    />
                  )}
                </div>
              </div>
              {/* /img do item */}
            </div>
          </div>
          <NextPrevLine data={nextPrevData} />

          <div className="container detalhe" >
            <div className="row justify-content-center">
              <div
                className="col-12 col-sm-12 justify-content-center"
                style={{ display: "flex", marginTop: "4rem" }}
              >
                <button
                  id="btn-voltar1"
                  type="button"
                  style={{ display: "flex", marginLEft: "40%" }}
                  className="btn btn-voltar"
                  onClick={(e) => {
                    navigate(-1);
                  }}
                >
                  Voltar
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
}
