import React from "react";
import { useState,useEffect } from "react/";

import BreadCrumbs from "../../components/BreadCrumbs";

import img_folha_navegacao_dir from '../../images/svg/folha_navegacao_dir.svg';
import img_face_1 from '../../images/orgaos/1.png';

import { useLocation, useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet-async";
import api, {siteURLShare,mseErroComunicacao} from '../../services/api'
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
export default function ConselhoPedagogico(){
    const location=useLocation();
    const navigate = useNavigate();
    const [loading,setLoading] = useState(true);
    const [data,setData]=useState([]);

    useEffect(() => {

        async function loadData() {

            //const response = 
            await api.get('pedagogico/all').then(response=>{
                if (response.data.length > 0) {
                    setData(response.data);
                    //console.log(response.data[1])
                }
                setLoading(false);
            }).catch(err=>{
                //toast.error(mseErroComunicacao);
                setTimeout(function(){
                    navigate("/erro-comunicacao");
                },5000);
            });
            //debugger;
            //console.log(response.data)
            
        }

        loadData();
        window.scrollTo(0, 0)
    }, []);
    const [breadCrumbsData,setBreadCrumbsData]= useState([
        {
            nome: "Home",
            url: "/"
        },
        {
            nome:"Agrupamento",
            url:null
        },
        {
            nome:"Conselho Pedagógico",
            url: "/conselho-pedagogico"
        }
    ]);

    return(
<section className ="content">        
    <div className="container detalhe">    
        <BreadCrumbs data={breadCrumbsData}/>
    </div>
    {/* Titulo */}
    <div className="container detalhe">
        <div className="row ligacao justify-content-left">
            <div className="col-12">
                <h1>Conselho Pedagógico</h1>
                <div className="row justify-content-left">
                    <div className="col-2">
                        <hr/>
                    </div>    
                </div>
            </div>
        </div>
    </div>

    {/* corpo detalhe */}
    <div className="container detalhe">
    {loading===true &&(
            <div className="row">    
                <div className="col-12">
                    <Loading></Loading>
                </div>
            </div>    
    )}
    {loading===false &&(
        

        <div className="row">
            <Helmet>
                <title>{`Agrupamento de Escolas de Monte da Ola ${'>'} Conselho Pedagógico`}</title>
                <meta name="description" content={`Orgãos de Gestão`}/>
                <meta name="keywords" content={`Website do Agrupamento de Escolas de Monte da Ola, Conselho Pedagógico`}/>
                {/*facebook*/}
                <meta name="og:title" content={`Conselho Pedagógico`}/>
                <meta name="og:url" content={siteURLShare+location.pathname} />
                <meta name="og:description" content={`Agrupamento de Escolas de Monte da Ola ${'>'} Conselho Pedagógico`}/>
                <meta name="og:type" content="website"/>
                {/*twitter*/}
                <meta name="twitter:card" content={`Conselho Pedagógico`}/>
                <meta name="twitter:title" content={`Conselho Pedagógico`}/>
                <meta name="twitter:description" content={`Agrupamento de Escolas de Monte da Ola ${'>'} Conselho Pedagógico`}/>
                <meta name="twitter:site" content={siteURLShare+location.pathname}/>
                <meta name="twitter:creator" content="Agrupamento de Escolas de Monte da Ola"/>
            </Helmet>
            {/* direita */}
            <div className="col-12 col-md-12 conselho-pedagogico">
            
                {/*<h2 style={{position:"relative",marginTop:"-67px"}}>Conselho Pedagógico</h2 >*/}
                {data.map((item1,index1)=>{
                    return(<div key={"A"+index1}>                                     
                        
                        <h3>{item1.position}</h3>
                        <p>{item1.person}</p>
                        
                    </div>
                    );
                })}
            </div> 
            {/* /direita */}
        </div>
        )}
    </div>
    
</section>
    );
}