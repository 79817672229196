import React,{useEffect,useState} from "react";


import { useNavigate,useLocation, useParams } from "react-router-dom";

import BreadCrumbs from "../../../components/BreadCrumbs";

import Loading from '../../../components/Loading'
//import './detalhe.css';
//import img_exames from '../../images/avaliacao/exames.jpg';
import api,{apiEndpoint,siteURL,siteURLShare,mseErroComunicacao} from "../../../services/api";
import {Helmet} from "react-helmet-async";
import Erro from '../../../pages/Erro'

import { LinkIrParaProjecto} from '../../../styles';
import setinhadireita from "../../../../src/images/svg/arrow_ir_proj.svg";

import './detalheprojectointegrador.css';

import image_default from '../../../images/images_default/placeholder_projetos.jpg'

import { toast } from 'react-toastify';

export default function DetalheProjetoIntegrador(params){
    
    
    

    const { slug } = useParams();
    const location=useLocation();
    const navigate=useNavigate();
    const [breadCrumbsData,setBreadCrumbsData]= useState([
        {
            nome: "Home",
            url: "/"
        }
        
    ]);

    

    const [data,setData]=useState([]);
    const[loading,setLoading]=useState(true)
    const[error404,setError404]=useState(false)
    
      useEffect(() => {
         
        async function loadData() {
            //console.log(location.pathname)
            let apiurl='';
            
            apiurl=`/projetosintegradores/projeto/${slug}`
            
            //const response = 
            await api.get(apiurl).then(response=>{
                //debugger;    
                //console.log("response",response,typeof(response.data[0]) )
                if( (typeof(response.data)=='object' && response.data.status_code!== undefined && response.data.status_code===404 )){
                    
                    setError404(true);
                    
                }
                else if (response.data.length > 0) {
                    //1 NextPrevLink
                    setData(response.data[0]);
                //console.log("data:",response.data)
                    
                //2
                setBreadCrumbsData(
                    [{
                        nome: "Home",
                        url: "/"
                    },
                    {
                        nome: "Projetos Integradores",
                        url: null
                    },
                    {
                        nome: response.data[0].type,
                        url: `/projetos-integradores-categoria/${encodeURIComponent(response.data[0].type_slug)}`
                    },
                    {
                        nome:response.data[0].title,
                        url: response.data[0].url
                    }             
                    ]);
                }
                setLoading(false);
            }).catch(err=>{
                //toast.error(mseErroComunicacao);
                setTimeout(function(){
                    navigate("/erro-comunicacao");
                },5000);
            });
            

            
        }

        loadData();
        

        //console.log(data)
    }, [slug]);
    

    if(error404 ===true){
       
        return(
            <>
            <Erro></Erro>
            </>
        );
    }

function tratadata(data){
    var options = {  day:'numeric',month: 'long',year:'numeric'};
    var date  = new Date(data);
    let data_str =date.toLocaleDateString("pt-pt",options).toString().replace(".","");
    options = {  month: 'long'};
    var mes1=date.toLocaleDateString("pt-pt",options).toString().replace(".","");
    var mes2=mes1.charAt(0).toUpperCase()+mes1.slice(1);
    //console.log("mes2=",mes2)
    
    data_str=data_str.replace(mes1,mes2);
     return data_str
}
    return(
<section className ="content">   
{loading===true && (<Loading></Loading>)} 


{loading===false && (

<Helmet>
<title>{`Agrupamento de Escolas de Monte da Ola ${'>'} ${data.title}`}   </title>

<meta name="keywords" content={`Website Agrupamento de Escolas de Monte da Ola, Projectos Integradores (${data.type}) > ${data.title}`}/>
{/*facebook*/}
<meta name="og:title" content={data.title}/>
<meta name="og:url" content={siteURLShare+location.pathname} />
{data.body!==null &&(
<meta name="og:description" content={`${data.body.replace(/<[^>]+>/g, '').substring(0,200)}`}/>
)}

<meta name="og:type" content="website"/>
{/*twitter*/}
<meta name="twitter:card" content={`Agrupamento de Escolas de Monte da Ola ${'>'} Projectos Integradores (${data.type}) > ${data.title}`}/>
<meta name="twitter:title" content={data.title}/>
{data.body!==null &&(
    <meta name="twitter:description" content={`${data.body.replace(/<[^>]+>/g, '').substring(0,200)}`}/>
)}

<meta name="twitter:site" content={siteURLShare+location.pathname} />
{data.body!==null &&(
<meta name="description" content={`${data.body.replace(/<[^>]+>/g, '').substring(0,200)}`}/>
)}
{data.body!==null &&(
<meta name="twitter:description" content={`${data.body.replace(/<[^>]+>/g, '').substring(0,200)}`}/>
)}
                    
{data.image!==null && (
     <meta name="twitter:image" content={data.image}/>
    
)}
{data.image!==null && (
    <meta name="og:image" content={data.image}/>
    
)}
{data.image===null && (
     <meta name="twitter:image" content={image_default}/>
    
)}
{data.image===null && (
    <meta name="og:image" content={image_default}/>
    
)}
<meta name="twitter:creator" content="Agrupamento de Escolas de Monte da Ola"/>
</Helmet>
)}
{loading===false && ( 
   <>
   <div className="container detalhe">    
        <BreadCrumbs data={breadCrumbsData}/>
    </div>
    {/* Titulo */}
    <div className="container detalhe">
        <div className="row ligacao justify-content-left">
            <div className="col-12">
                <h1>{data.title}</h1>
                <p>{tratadata(data.publishDate)}</p>
                <div className="row justify-content-left">
                    <div className="col-2">
                        <hr/>
                    </div>    
                </div>
            </div>
        </div>
    </div>

    {/* corpo detalhe */}
    <div className="container detalhe">
        <div className="row">
        {data.body!==null &&(
                <div className="col-12 col-sm-12 col-md-8 col-lg-8 artigo-info" dangerouslySetInnerHTML={{ __html:data.body}}>
                {/*corpo de texto do item inicio*/}
                
                 {/*corpo de texto do item fim*
                <button className="btn btn-share" type="button"></button>    */}               
                </div>
            )}
            {data.body===null &&(
                <div className="col-12 col-sm-12 col-md-8 col-lg-8 artigo-info">
                {/*corpo de texto do item inicio*/}
                    {data.link!==null &&(
                        <a href={data.link}>Esta página abre um link externo</a>
                    )}

                 {/*corpo de texto do item fim*
                <button className="btn btn-share" type="button"></button>    */}               
                </div>
            )}
            
            {/*img do item */}
            
                <div className="col-12 col-sm-12 col-md-4 col-lg-4 projectos">
                    <div className="image-artigo">
                    {data.image!== null && data.image.length>0 &&(
                        <img src={data.image}  className="d-block w-100" alt={data.title} />
                    )}
                    {data.image=== null  &&(
                        <img src={image_default}  className="d-block w-100" alt={data.title} />
                    )}
                    </div>
                </div>
            
            
            {/* /img do item */}
            </div>
            {data.link!==null && data.link.length>0 &&(
                <div className="row">
                    <div className="col-12 col-sm-12 justify-content-start" style={{display:"flex",marginTop:"4rem"}}>
                        <LinkIrParaProjecto target="_blank" href={data.link}>Ir para projeto <img src={setinhadireita}></img></LinkIrParaProjecto>
                    </div>
                </div>

            )} 
            
            <div className="col-12 col-sm-12 justify-content-center" style={{display:"flex",marginTop:"4rem"}}>
                <button id="btn-voltar1" type="button" style={{display:"flex"}} className="btn btn-voltar" onClick={(e) => { navigate(-1); }}>
                    Voltar
                </button>
            </div>
        </div>
        
   
</>
    )}
</section>
    );
}