import React from 'react';
import { useState } from 'react';

import { LinkNext,LinkPrev } from '../../styles';

import './nextprevline.css'

    export default function NextPrevLine(props){
        
        const [data,setData]=useState(props.data);
        //console.log("props",props)
        return(
            <div className="container detalhe nextprevline">
                <div className="row botoes justify-content-space-between">
                    {/* parte esquerda */}
                    {
                    props.data.nextUrl!== null && 
                        <div className="col-6 btn-esq">
                            <LinkPrev href={props.data.nextUrl} className="btn btn-prev">
                            <span>{props.data.nextName}</span>
                            </LinkPrev>
                        </div>
                    }
                    {
                    props.data.nextUrl=== null && 
                        <div className="col-6 btn-esq">
                            
                        </div>
                    }


                    {/* parte direita */}
                    {
                    props.data.prevUrl!== null && 
                    <div className="col-6 btn-dir">
                        <LinkNext href={props.data.prevUrl} className="btn btn-next">
                        <span>{props.data.prevName}</span>
                        </LinkNext>
                    </div>
                    }
                    {
                    data.prevUrl=== null && 
                    <div className="col-6 btn-dir">                        
                    </div>
                    }    
                </div>
            </div>
        );
    } 