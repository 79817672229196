import React,{useEffect,useState} from 'react';
import { useNavigate,useParams,useLocation } from 'react-router-dom';
import Loading from '../../../components/Loading'
//import './detalhe.css';
import img_contactos from '../../../images/contactos.jpg';
import api,{apiEndpoint,siteURL,siteURLShare, mseErroComunicacao} from "../../../services/api";

import Erro from '../../Erro'
import BreadCrumbs from '../../../components/BreadCrumbs';
import {Helmet} from "react-helmet-async";


import './itemcontactos.css';
import { toast } from 'react-toastify';
export default function ItemContatos(){

    const { slug } = useParams();
    const location=useLocation();
    const navigate=useNavigate();
    const [breadCrumbsData,setBreadCrumbsData]= useState([
        {
            nome: "Home",
            url: "/"
        }
        
    ]);   

    
    const [data_p,setData_P]=useState({});
    const [data,setData]=useState([]);
    const[loading,setLoading]=useState(true)

    useEffect(() => {
         
        async function loadData() {
            //console.log(location.pathname)
            
            //const response = 
            await api.get("escola/contatos").then(response=> {
                //console.log("response",response,typeof(response.data[0]) )
                if(typeof(response.data)=='object' && response.data.status_code===404 ){
                    
                   // setError404(true);
                    
                }
                else if (response.data.length > 0) {
                    //1 NextPrevLink
                    setData_P(response.data[0])
                    setData(response.data[1]);
                    //console.log("data:",response.data)
                    
                   //2 
                   let newbread=[{
                            nome: "Home",
                            url: "/"
                        },
                        {
                            nome: "Agrupamento",
                            url: null
                        },
                        {
                            nome:response.data[0].title,
                            url: response.data[0].url
                        }]
                //console.log(newbread)
                   setBreadCrumbsData(newbread);
                }
                setLoading(false);
            }).catch(err=>{
                //toast.error(mseErroComunicacao);
                setTimeout(function(){
                    navigate("/erro-comunicacao");
                },5000);
            });
            

            
        }

        loadData();
        setLoading(false);
    }, []);
    function extractEmails (text) {
        if(text===null) return "";
        return text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
      }
    return (
        <section className="content">
            {loading === true && (<Loading></Loading>)}

            {loading === false && (
                <>
                    <div className="container detalhe">
                        <BreadCrumbs data={breadCrumbsData} />
                    </div>
                    {/* Titulo */}
                    <Helmet>
                        <title>{`Agrupamento de Escolas de Monte da Ola ${'>'} ${data_p.title}`}</title>
                        <meta name="description" content={data_p.title}/>
                        <meta name="keywords" content={`Website do Agrupamento de Escolas de Monte da Ola, ${data_p.title}`}/>
                        {/*facebook*/}
                        <meta name="og:title" content={data_p.title}/>
                        <meta name="og:url" content={siteURLShare+location.pathname} />
                        <meta name="og:description" content={`Agrupamento de Escolas de Monte da Ola ${'>'} ${data_p.title}`}/>
                        <meta name="og:type" content="website"/>
                        {/*twitter*/}
                        <meta name="twitter:card" content={data_p.title}/>
                        <meta name="twitter:title" content={data_p.title}/>
                        <meta name="twitter:description" content={`Agrupamento de Escolas de Monte da Ola ${'>'} ${data_p.title}`}/>
                        <meta name="twitter:site" content={siteURLShare+location.pathname}/>
                        <meta name="twitter:creator" content="Agrupamento de Escolas de Monte da Ola"/>
                    </Helmet>
                    
                    <div className="container detalhe">
                        <div className="row ligacao justify-content-left">
                            <div className="col-12">
                                <h1>{data_p.title}</h1>
                                
                                <div className="row justify-content-left">
                                    <div className="col-2">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* corpo detalhe */}
                    <div className="container detalhe">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-8 col-lg-8 contactos-info">
                                {data.map((item,index)=>{                                        
                                            return (
                                                <div className="contacto" key={index}>
                                                    {index===0 &&( /* escola main */
                                                        <>
                                                            <h3>{item.name}</h3>
                                                            <p>
                                                                {item.address}<br />
                                                                {item.postalCode}
                                                            </p>
                                                            <p>{item.place}</p>
                                                            {item.info.map((item1,index1)=>{
                                                                let emails=""
                                                                if(item1!==null){
                                                                    emails=extractEmails(item1);
                                                                }
                                                                let label="";
                                                                if(index1==0)label="Telf.: ";
                                                                if(index1==1)label="Email: ";
                                                                if(index1==2)label="Fax: ";
                                                                
                                                                let html_emails=""
                                                                let retornar="";
                                                                if(emails!==null && emails.length>0){
                                                                    for(var i=0;i< emails.length;i++){
                                                                        html_emails+='<a href="mailto:'+emails[i]+'">'+emails[i]+"</a><br>";
                                                                    }
                                                                    html_emails="<span>Email: </span>"+html_emails; 
                                                                    retornar+=html_emails;
                                                                    //return  <p dangerouslySetInnerHTML={{__html:html_emails}}></p>
                                                                }else{
                                                                    html_emails=item1
                                                                    retornar+=`<span>${label}</span>`+item1;
                                                                }
                                                                
                                                                return(<p key={index1} dangerouslySetInnerHTML={{__html:retornar}}></p>);    
                                                            })}
                                                    
                                                        </>
                                                    )}

                                                    {index > 0 &&( /* escolas agrupamento */
                                                        <>
                                                        <h2>{item.freguesia}</h2>
                                                        {item.escolas.length>0 &&(
                                                            <>
                                                            {item.escolas.map((item2,index2)=>{
                                                                return(
                                                                            <div className="contacto" key={index2}>
                                                                                <h3>{item2.name}</h3>
                                                                                <p>
                                                                                    {item2.address}<br />
                                                                                    {item2.postalCode}
                                                                                </p>
                                                                                <p>{item2.place}</p>
                                                                                {/* map do info das escolas */}
                                                                                <ul style={{listStyle:"none",color: "#737373",fontWeight: "400",fontSize: "20px",margin: "auto 0",paddingLeft:"0px"}}>
                                                                            {item2.info.map((item3, index3) => {
                                                                                //console.log(item2.name+"----------------------")
                                                                                if (item3 !== null) {


                                                                                    var retornar = "";
                                                                                    if (index3 === 0) {
                                                                                        //são telefgones
                                                                                        let arr_tel = item3.split(",")
                                                                                        let resultado = "";

                                                                                        if (arr_tel.length > 0) {

                                                                                            for (var i = 0; i < arr_tel.length; i++) {
                                                                                                resultado +=arr_tel[i];
                                                                                                if(i<arr_tel.length-1) resultado += " / ";
                                                                                            }
                                                                                        }
                                                                                        return (
                                                                                            <li key={"tel" + index3} dangerouslySetInnerHTML={{ __html: "Telf.: "+resultado }}></li>
                                                                                        );

                                                                                    }
                                                                                    if (index3 === 1) {
                                                                                        //sa0 os emails
                                                                                        let emails = extractEmails(item3);
                                                                                        //console.log("item3",item3)
                                                                                        //console.log("emails",emails)
                                                                                        let html_emails = ""
                                                                                        if (emails !== null && emails.length > 0) {
                                                                                            for (var i = 0; i < emails.length; i++) {
                                                                                                html_emails = '<a href="mailto:' + emails[i] + '">' + emails[i] + "</a><br>";
                                                                                            }
                                                                                            html_emails = "<span>Email: </span>" + html_emails;
                                                                                            return <p dangerouslySetInnerHTML={{ __html: html_emails }}></p>
                                                                                        } else {
                                                                                            html_emails = item3
                                                                                        }
                                                                                        return (<li key={"emails" + index3}><p>{emails !== null && emails.length > 0 && (<span>Email:</span>)} {html_emails}</p></li>);
                                                                                    }
                                                                                    if (index3 === 2) {
                                                                                        //são faxes
                                                                                        let resultado = "";
                                                                                        let arr_tel = item3.split(",")
                                                                                        
                                                                                        if (arr_tel.length > 0) {

                                                                                            for (var i = 0; i < arr_tel.length; i++) {
                                                                                                resultado +=arr_tel[i];
                                                                                                if(i<arr_tel.length-1) resultado += " / ";
                                                                                            }
                                                                                        }
                                                                                        return (
                                                                                            <li key={"fax" + index3} dangerouslySetInnerHTML={{ __html:  "Fax.: "+resultado }}></li>
                                                                                        );

                                                                                    }
                                                                                }
                                                                            })}
                                                                                </ul>
                                                                            </div>
                                                                    );    
                                                                })}
                                                                
                                                            </>
                                                        )}
                                                        </>  )  
                                                    }       
                                                            
                                                            
                                                    
                                                    
                                                     
                                                </div>
                                            );
                                                                              
                                
                                })}
                                {/*corpo de texto do item inicio*   
                                   
                                    <div className="contacto">
                                        1<h3>Escola Básica e Secundária de Monte da Ola</h3>
                                        2<h5>(Escola Sede)</h5>
                                        2<p>Largo do Monte da Ola, Nº 257</p>
                                        4<p>4935-370 Vila Nova de Anha</p>
                                        5<p>Telefeone: 258 320 460</p>
                                        6<p>Fax: 258 331 577</p>
                                        7<p>Email: <span>geral@escolasmontedaola.pt</span></p>
                                        8<p><span>secretaria@escolasmontedaola.pt</span></p>
                                    </div>
                                */}

                                {/*corpo de texto do item fim*
                        <button className="btn btn-share" type="button"></button>    */}
                            </div>
                            {/*img do item */}
                            {/*data.image !== null && data.image.length > 0 && (*/}
                                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                    <div className="image-artigo">
                                        <img src={img_contactos} className="d-block w-100" alt="img-m" />
                                    </div>
                                </div>
                           {/*} )*/}

                            {/* /img do item */}
                        </div>

                    </div>
                </>
            )}
        </section>
    );
}
