import React,{useEffect,useState,useContext} from "react";

import {Link} from 'react-router-dom';

import Zona1Slider from './components/Zona1Slider';
//import Zona2Actividade from './components/Zona2Actividade';
import Zona2ActividadeTinySlider from './components/Zona2ActividadesTinySlider';
import Zona3LigExternas from './components/Zona3LigExternas';

import Zona4Video from './components/Zona4Video';

import Zona5NoticiasAnuncios from './components/Zona5NoticiasAnuncios';

import Zona6Newsletter from './components/Zona6Newsletter';
import Zona7VideoMapa from './components/Zona7VideoMapa';
import './home.css';

import RedirectedContext, { RedirectContextProvider } from "../../contexts/global";

//teste 
import CustomForm from "./components/Zona6Newsletter/index"


export default function Home() {
  const contexto = useContext(RedirectedContext);

    useEffect(()=>{
      var dictionairyOfUrlSCrolls = JSON.parse(localStorage.getItem("dictionairyOfUrlSCrolls"));
      if (dictionairyOfUrlSCrolls != null && dictionairyOfUrlSCrolls != undefined)
          for (var i = 0; i < dictionairyOfUrlSCrolls.length; i++) {
              if (dictionairyOfUrlSCrolls[i].url == window.location+window.location.pathname.substring(1,window.location.pathname.length)) {
                  //alert("match")
                  //dictionairyOfUrlSCrolls[i].scrollY=position;
                  window.scrollTo(0, dictionairyOfUrlSCrolls[i].scrollY);
                  contexto.setRedirected(false);
                  return;
              }
          }
    },[<Zona1Slider />,<Zona2ActividadeTinySlider />,<Zona3LigExternas />, <Zona4Video />,<Zona7VideoMapa />]);
    function componentDidMount() {    
//alert("componentDIDMOUNT")

    }
   
    return (
      <>
      <Zona1Slider />
      <Zona2ActividadeTinySlider />
      {/*<Zona2Actividade />*/}
      <Zona3LigExternas />
       <Zona4Video />
      <Zona5NoticiasAnuncios />
      {/*<Zona6Newsletter />*/}
      <CustomForm></CustomForm>
      <Zona7VideoMapa />
      </>
    );
}