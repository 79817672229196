import React,{useEffect,useState} from "react";


import { useNavigate,useLocation, useParams } from "react-router-dom";

import BreadCrumbs from "../../components/BreadCrumbs";

import Loading from '../../components/Loading'
//import './detalhe.css';
import img_documentos_orientadores from '../../images/listalinks/documentosorientadores.jpg';
import img_manuais_escolares from '../../images/listalinks/manuaisescolares.jpg';

import api,{apiEndpoint,siteURL,siteURLShare, mseErroComunicacao} from "../../services/api";

import {Helmet} from "react-helmet-async";

import Erro from '../../pages/Erro'
import { toast } from 'react-toastify';
import './listalinks.css';

export default function LstaLinks(props){
    const [titulo,setTitulo ] = useState(props.title);
    const location=useLocation();
    const navigate=useNavigate();
    const [breadCrumbsData,setBreadCrumbsData]= useState([
        {
            nome: "Home",
            url: "/"
        }
        
    ]);

    

    const [data,setData]=useState([]);
    const[loading,setLoading]=useState(true)
    const[error404,setError404]=useState(false)
    const[imagemLateral,setImagemLateral]=useState("");
      useEffect(() => {
         
        async function loadData() {
            
            //const response = 
            await api.get(props.apiendpoint).then(response=>{
                if(typeof(response.data)=='object' && response.data.status_code===404 ){
                
                    setError404(true);
                    
                }
                else if (response.data.length > 0) {
                    //1 NextPrevLink
                    setData(response.data);
                    //console.log("data:",response.data)
                    
                   //2
                   if(props.apiendpoint==='documentos/all'){
                    setImagemLateral(img_documentos_orientadores);
                    setBreadCrumbsData(
                        [{
                            nome: "Home",
                            url: "/"
                        },
                        {
                            nome: "Agrupamento",
                            url: null
                        },
                        {
                            nome:props.title,
                            url: location.pathname
                        }             
                        ]);
                }else if(props.apiendpoint==='manuais/all'){
                    setImagemLateral(img_manuais_escolares);
                    setBreadCrumbsData(
                        [{
                            nome: "Home",
                            url: "/"
                        },
                        {
                            nome: "Alunos",
                            url: null
                        },
                        {
                            nome:props.title,
                            url: location.pathname
                        }             
                        ]);
                    }
                }
                setLoading(false);
            }).catch(err=>{
                //toast.error(mseErroComunicacao);
                setTimeout(function(){
                    navigate("/erro-comunicacao");
                },5000);
            });
            //console.log("response",response,typeof(response.data[0]) )
            

            
        }

        loadData();
        

        //console.log(data)
    }, []);
    

    if(error404 ===true){
       
        return(
            <>
            <Erro></Erro>
            </>
        );
    }

    return(
<section className ="content">   
{loading===true && (<Loading></Loading>)} 

{loading===false && ( 
   <>
   <div className="container detalhe">    
        <BreadCrumbs data={breadCrumbsData}/>
    </div>
    {/* Titulo */}
    <Helmet>
        <title>{`Agrupamento de Escolas de Monte da Ola ${'>'} ${titulo}`}</title>
        <meta name="description" content={data.map((item,index)=>{
                      return(
                          `${item.name},`
                      );  
                })}/>
        <meta name="keywords" content={`Website do Agrupamento de Escolas de Monte da Ola,${titulo}`}/>
        {/*facebook*/}
        <meta name="og:title" content={data.title}/>
        <meta name="og:url" content={siteURLShare+location.pathname} />
        <meta name="og:description" content={data.map((item,index)=>{
                      return(
                          `${item.name},`
                      );  
                })}/>
        <meta name="og:image" content={imagemLateral}/>
        <meta name="og:type" content="website"/>
        {/*twitter*/}
        <meta name="twitter:card" content="Agrupamento de Escolas de Monte da Ola"/>
        <meta name="twitter:title" content={titulo}/>
        <meta name="twitter:description" content={data.map((item,index)=>{
                      return(
                          `${item.name},`
                      );  
                })}/>
        <meta name="twitter:site" content={siteURLShare+location.pathname}/>
        <meta name="twitter:image" content={imagemLateral}/>
        <meta name="twitter:creator" content="Agrupamento de Escolas de Monte da Ola"/>
    </Helmet>
    <div className="container detalhe">
        <div className="row ligacao justify-content-left">
            <div className="col-12">
                <h1>{titulo}</h1>                
                <div className="row justify-content-left">
                    <div className="col-2">
                        <hr/>
                    </div>    
                </div>
            </div>
        </div>
    </div>

    {/* corpo detalhe */}
    <div className="container detalhe lista-links">
        <div className="row">
            <div className="ccol-12 col-sm-12 col-md-8 col-lg-8 artigo-info" >
                
                
                {/*corpo de texto do item inicio*/}
                <ul>
                {data.map((item,index)=>{
                      return(
                          <li key={index}><a href={item.file} target="_blank">{item.name}</a></li>
                      );  
                })}
                </ul>
                 {/*corpo de texto do item fim*
                <button className="btn btn-share" type="button"></button>    */}               
            </div>
           
            {/*img do item */}
            
            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <div className="image-artigo">
                    <img src={imagemLateral}  className="d-block w-100" alt="img-m" />
                </div>
            </div>
            {/* /img do item */}

            <div className="col-12 col-sm-12 justify-content-center" style={{display:"flex",marginTop:"4rem"}}>
                <button id="btn-voltar1" type="button" style={{display:"flex"}} className="btn btn-voltar" onClick={(e) => { navigate(-1); }}>
                    Voltar
                </button>
            </div>
        </div>
        
    </div>
</>
    )}
</section>
    );
}