
import Rotas from './routes';

function App() {

  //detectar o browser  
  const isFirefox = typeof InstallTrigger !== 'undefined';

  return (
    <div className={`App ${isFirefox ? 'isFirefox':'' }`}>
      <Rotas />
    </div>
  );
}

export default App;
