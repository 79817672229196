import React,{useEffect,useState} from "react";
import { useParams,useNavigate, Link } from "react-router-dom";

import Loading from '../../components/Loading'

import './resultadospesquisa.css';

import ReactPaginate from "react-paginate";

import icon1_arrow_left from '../../../src/images/svg/arrow-left-square.svg'
import icon1_arrow_right from '../../../src/images/svg/arrow-right-square.svg'
import icon_x_circle from '../../../src/images/svg/x-circle.svg';
import icon_search from '../../../src/images/svg/search.svg';
import icon_arrow_left from '../../../src/images/svg/arrow_left_mac.svg';
import icon_arrow_right from '../../../src/images/svg/arrow_right_mac.svg';
import api,{apiEndpoint,siteURL,mseErroComunicacao} from "../../services/api";


import link_pag_next from '../../images/svg/arrow_right_mac.svg';
import link_pag_prev from '../../images/svg/arrow_left_mac.svg';

import profile_default from '../../images/images_default/profile_default.png';

/* imagens default*/
import img_default_noticias from '../../images/images_default/noticias_pesquisa.jpg';
import img_default_concursos from '../../images/images_default/concursos_pesquisa.jpg';
import img_default_projectos from '../../images/images_default/projetos_pesquisa.jpg';
import img_default_atividades from '../../images/images_default/atividades_pesquisa.jpg';
import img_default_exames from '../../images/images_default/exames_pesquisa.jpg';



import { toast } from 'react-toastify';

export default function ResultadosPesquisa(){
    ///:pesq/:page/:page_size"
    const {pesq,page,page_size} = useParams();
    const [pesq1,setPesq1]=useState('')
    let navigate = useNavigate();
    
    //const page_size= 2

    const [currentPage, setcurrentPage] = useState(page); //#New
    
    const [paginationData,setPaginationData]=useState({
        page:null,
        size:null,
        total:null
    })

    const [data,setData]=useState([]);
    const[loading,setLoading]=useState(true)
    const[error404,setError404]=useState(false)
    const[btnLeftDisabled,setBtnLeftDisabled]=useState(false);
    const[btnRightDisabled,setBtnRightDisabled]=useState(false);
    const[displayArrows,setdisplayArrows]=useState('none');
     
    const[pesquisado,setPesquisado]=useState({termo: "", pesquisado: false})
    const [btnXVisible,setBntXVisible]=useState("block");
    const [btnLupaVisible,setBntLupaisible]=useState("none");
    /*function handleScroll() {
       try{
        window.scroll({
            top: document.body.offsetHeight,
            left: 0, 
            behavior: 'smooth',
          });
       }catch(e){}
      }
    */
    
    useEffect(() => {

        async function loadData() {
            let pesq1= encodeURIComponent(pesq)
            
           
            setPesq1(pesq)
            let URL='';
            
            URL=`/search/${pesq1}?page=${page}&size=${page_size}` 
            
           
            //console.log("URL",URL)
            try {
                //const response = 
                await api.get(URL).then(response=>{
                    setPesquisado({termo:pesq1, pesquisado:true});
                    setBntLupaisible("none");
                    setBntXVisible("block");
                    if (response.data.items.length >= 0) {
                        //1 NextPrevLink
                        setData(response.data.items);
                        setPaginationData({
                            page: response.data.page,
                            size: response.data.size,
                            total: response.data.total
                        })
                        if(parseInt(response.data.total)<parseInt(response.data.size)){
                            setdisplayArrows('none');
                        }else{
                            setdisplayArrows('flex');
                        }
                        if ((response.data.page * response.data.size) >= response.data.total) {
                            setBtnRightDisabled(true)
                        } else {
                            setBtnRightDisabled(false)
                        }
                        if (response.data.page <= 1) {
                            setBtnLeftDisabled(true)
                        } else {
                            setBtnLeftDisabled(false)
                        }
                        setLoading(false);
                    }
                
                }).catch(function (error) {
                        
                    //toast.error(mseErroComunicacao);
                    setTimeout(function(){
                        navigate("/erro-comunicacao");
                    },5000);
                });;


                //console.log("response", response, typeof (response.data))
               
                
            } catch (error) {
                //console.log("Catch Error: ",error)

            }
            
            
           
            window.scrollTo(0, 0)
            
        }

        loadData();
        

        window.scrollTo(0, 0)
    }, [pesq,page,currentPage,page_size]);

    const handlePageChange = (selectedObject) => {

		setcurrentPage(selectedObject.selected);        	
        window.scrollTo({top: 0, behavior: 'smooth'});
        navigate(`/pesquisa/${encodeURIComponent(pesq1)}/${parseInt(selectedObject.selected)+parseInt(1)}/${page_size}`);
	};
    function handleChangePesq1(valor){
        
        setPesq1(valor)
        if(pesquisado.termo==valor && pesquisado.pesquisado===true){
            //setPesquisado({termo:pesq1}, pesquisado=true);
            setBntLupaisible(false);
            setBntXVisible(true);
        }else{
            setPesquisado({termo:valor, pesquisado:false});
            setBntLupaisible("block");
            setBntXVisible("none");
        }
        
    }
    function processaPesquisa(e){
        e.preventDefault();    
        let p=pesq1;

        if(pesquisado.termo==p){
            if(pesquisado.pesquisado===true){
                return; // nao repete a pesquisa
            }

        } 
        //setPesq1('')
        //alert(encodeURIComponent(p))
        /*setData([])
        setPaginationData({
            page:null,
            size:null,
            total:null
        });*/
        //setLoading(true)
        if(p.length>0){
             
            setdisplayArrows('none')
            navigate("/pesquisa/"+encodeURIComponent(p)+"/1/10");
        }
    }

    function handleKeyPress(event) {
        if(event.key === 'Enter'){
            processaPesquisa(event);
            
          }
          
        }
function tratadata(data){
            var options = {  day:'numeric',month: 'numeric',year:'numeric'};
            var date  = new Date(data);
            let data_str =date.toLocaleDateString("pt-pt",options).toString().replace(".","");
            //options = {  month: 'long'};
            //var mes1=date.toLocaleDateString("pt-pt",options).toString().replace(".","");
            //var mes2=mes1.charAt(0).toUpperCase()+mes1.slice(1);
            //console.log("mes2=",mes2,"mes1",mes1)
            
            //data_str=data_str.replace(mes1,mes2).replace("/", "-").replace("/", "-");
            data_str=data_str.replace("/", "-").replace("/", "-");
            //console.log(data_str)
            return data_str
}
function tratatype(type){
    //console.log("type",type)
    switch(type){
        case "item": return("Página"); break;
        case "anuncio": return("Anúncio");break;
        case "atividade":return("Atividade");break;
        case "noticia":return("Notícia");break;
        case "avaliacao-externa": return("Avaliação Externa");break;
        case "orgaos-de-gestao": return("Orgãos de Gestão");break;
        case "conselho-geral": return("Conselho Geral");break;
        default: return("Por tratar!");break;

    }

}
    var rotateIcon=pesq1.length>0?"rotate-45":"";
    return(
        <section className="content">
           
            {/* Titulo */}
        <div className="container listagem pesquisa">
            <div className="row ligacao justify-content-center ">
                    <div className="col-12">

                       <div className="input-group mb-3">
                            <input value={pesq1} onKeyPress={(e)=>{handleKeyPress(e)}}  onChange={(e)=>{handleChangePesq1(e.target.value);}} type="text" className="form-control" placeholder="Insira o termo a pesquisar" aria-label="Insira o termo a pesquisar..." aria-describedby="button-addon2" />
                            <button className={`btn btn-pesquisa`} type="button" id="button-addon2">
                                {/*pesq1.length>0 && (*/}
                                <img id="imgbtnx" style={{display:btnXVisible}} src={icon_x_circle} onClick={(e)=>{setPesq1('');setBntXVisible('none');setBntLupaisible("block"); processaPesquisa(e);}} />
                                {/*)}*/}
                                {/*pesq1.length===0 && (*/}
                                    <img id="imgbtnlupa" style={{display:btnLupaVisible,marginRight:"10px"}} src={icon_search} onClick={(e)=>processaPesquisa(e)} />
                                    
                                {/*)}*/}
                            </button>
                        </div> 
                        
                    </div>
                </div>


                {loading===true &&(
                    <Loading/>                
                )}
                {loading!==true && (
                    <>
                       
                        <div className="row">
                            <div className="col-12">
                                <div className="pesqTit">
                                    <div><h1><b>{paginationData.total ? paginationData.total : 0}</b>&nbsp;resultados encontrados</h1></div>

                                    <div id="Navigation0" style={{display: `${displayArrows}`}}>
                                        <button className="btn btn-default"
                                            disabled={btnLeftDisabled}
                                            onClick={(e) => { navigate("/pesquisa/" + encodeURIComponent(pesq1) + "/" + parseInt(parseInt(page) - 1) + "/10"); }}>
                                            <img src={icon1_arrow_left}></img>
                                        </button>
                                        <button className="btn btn-default"
                                            disabled={btnRightDisabled}
                                            onClick={(e) => { navigate("/pesquisa/" + encodeURIComponent(pesq1) + "/" + parseInt(parseInt(page) + 1) + "/10"); }} ><img src={icon1_arrow_right} ></img></button>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <ul className="list-group resultados_pesqiosa">
                                    {data.map((item, index) => {
                                           //let label_origem=""; 
                                           let imagem_default=null
                                           switch(item.type){
                                               case "atividade": 
                                             //                   label_origem="Atividade";
                                                                imagem_default=img_default_atividades;
                                                            break;
                                                case "noticia":    
                                               //             label_origem="Notícia";
                                                            imagem_default=img_default_noticias;
                                                        break;             
                                                case "concurso":    
                                                 //       label_origem="Concurso";
                                                        imagem_default=img_default_concursos;
                                                    break;
                                                case "avaliacao-externa":
                                                   //     label_origem="Avaliação Externa";
                                                        imagem_default=img_default_exames;
                                                    break;   
                                                case "PPM": 
                                                case "TEIP":    
                                                  //  label_origem="Notícia";
                                                    imagem_default=img_default_projectos;
                                                break;   
                                                case "conselho-geral":
                                                case "orgaos-de-gestao":
                                                    imagem_default=profile_default;
                                                    break; 
                                            }     


                                        return (
                                            <li className="list-group-item resultado_pesquisa" key={index}>

                                                <div className="row">
                                                    {item.imagem !== null && item.imagem.length > 0 && (

                                                        <div className="col-4 col-md-3 col-lg-2" >
                                                            <img className="img-fluid img-thumbnail" width="240" height="240" style={{ display: "inline", float: "left" }} src={item.imagem}></img>
                                                        </div>

                                                    )}
                                                    {item.imagem === null && imagem_default!==null && (

                                                    <div className="col-4 col-md-3 col-lg-2" >
                                                        <img className="img-fluid img-thumbnail" style={{ display: "inline", float: "left" }}  width="240" height="240" src={imagem_default}></img>
                                                    </div>

                                                    )}
                                                    <div className="col-8 col-md-9 col-lg-10  flex1">
                                                        <h2>{item.title}</h2>
                                                        <h4 dangerouslySetInnerHTML={{ __html: item.body.substring(0, 250) + (item.body.length>150?"...":"") }}></h4>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h6 ><b className="label-actualizado">Atualizado: </b><span  className="label-publish-date">{tratadata(item.publishDate)}</span><b>Origem: </b><span>{item.url}</span></h6><Link className="btn btn-default" style={{ float: "right" }} to={item.url}>VER</Link>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>

                            </div>
                        </div>
                      

                        {(paginationData.total / page_size) > 1 && (
                            <div className="row ligacao justify-content-center">
                                <div className="col-12" style={{ display: "flex", justifyContent: "center" }}>

                                    <ReactPaginate id="pagination"

                                        pageCount={paginationData.total / page_size}

                                        pageRange={page_size}

                                        marginPagesDisplayed={2}

                                        forcePage={page - 1}
                                        initialPage={page - 1}

                                        onPageChange={handlePageChange}

                                        containerClassName={'container_pagination'}

                                        previousLinkClassName={'page_pagination_previous'}

                                        breakClassName={'page_pagination'}

                                        nextLinkClassName={'page_pagination_next'}

                                        pageClassName={'page_pagination'}

                                        disabledClassNae={'disabled_pagination'}

                                        activeClassName={'active_pagination'}

                                        nextLabel={<img src={link_pag_next}/>}
                                        previousLabel={<img src={link_pag_prev}/>}
                                    />
                                </div>
                            </div>
                        )}
                       
                    </>
                 )} 
            </div>
        </section>
    );
}