import React, { useState, useEffect } from "react";
import BreadCrumbs from "../../../components/BreadCrumbs";

import { useParams, useLocation, useNavigate } from "react-router-dom";

import { LinkLerMais, ButtonkLerMais } from "../../../styles";

import img_etwinning from "../../../images/noticias/etwinning.jpg";
import overlay from "../../../images/overlay.png";

import Loading from "../../../components/Loading";
import { Helmet } from "react-helmet-async";
import api, {
  apiEndpoint,
  siteURL,
  siteURLShare,
  mseErroComunicacao,
} from "../../../services/api";
import Erro from "../../Erro";

import imagem_default_lst from "../../../images/images_default/placeholder_projetos_392x350.jpg";

import { toast } from "react-toastify";

export default function ListagemProjetosIntegradores() {
  const location = useLocation();
  const navigate = useNavigate();
  const [breadCrumbsData, setBreadCrumbsData] = useState([
    {
      nome: "Home",
      url: "/",
    },
    {
      nome: "PAA/Projetos Integradores",
      url: null,
    },
  ]);
  const { type_slug } = useParams();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error404, setError404] = useState(false);

  useEffect(() => {
    async function loadData() {
      let url = `projetosintegradores/type/${type_slug}`;
      url = encodeURI(url);
      //const response =
      await api
        .get(url)
        .then((response) => {
          //debugger;
          if (
            response.data === null ||
            (typeof response.data == "object" &&
              response.data.status_code === 404)
          ) {
            setError404(true);
          } else if (response.data.length > 0) {
            setData(response.data);
            setBreadCrumbsData([
              {
                nome: "Home",
                url: "/",
              },
              {
                nome: "PAA/Projetos Integradores",
                url: null,
              },
              {
                nome: response.data[0].type,
                url: `/projetos-integradores-categoria/${encodeURIComponent(
                  response.data[0].type_slug
                )}`,
              },
            ]);
          }

          setLoading(false);
        })
        .catch((err) => {
          //toast.error(mseErroComunicacao);
          setTimeout(function () {
            navigate("/erro-comunicacao");
          }, 5000);
        });
    }

    loadData();
  }, []);

  if (error404 === true) {
    return (
      <>
        <Erro></Erro>
      </>
    );
  }
  return (
    <section className="content">
      <div className="container listagem">
        <BreadCrumbs data={breadCrumbsData} />
      </div>
      {/* Titulo */}
      {loading === false && (
        <div className="container listagem">
          <div className="row ligacao justify-content-center">
            <div className="col-12">
              <h1>{data[0].type}</h1>
              <div className="row justify-content-center">
                <div className="col-2">
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Listagem */}
      <div className="container listagem">
        <div className="row info">
          {loading === true && (
            <div className="col-12">
              <Loading></Loading>
            </div>
          )}
          {loading === false && (
            <Helmet>
              <title>
                {`Agrupamento de Escolas de Monte da Ola ${">"} Projectos Integradores (${
                  data.type
                })`}{" "}
              </title>

              <meta
                name="keywords"
                content={`Website Agrupamento de Escolas de Monte da Ola, PPM - Plano Anual de Atividades`}
              />
              {/*facebook*/}
              <meta
                name="og:title"
                content={`Agrupamento de Escolas de Monte da Ola ${">"} Projectos Integradores (${
                  data.type
                })`}
              />
              <meta name="og:url" content={siteURLShare + location.pathname} />

              <meta name="og:type" content="website" />
              {/*twitter*/}
              <meta
                name="twitter:card"
                content={`Agrupamento de Escolas de Monte da Ola ${">"} Projectos Integradores (${
                  data.type
                })`}
              />
              <meta
                name="twitter:title"
                content={`Agrupamento de Escolas de Monte da Ola ${">"} Projectos Integradores (${
                  data.type
                })`}
              />

              <meta
                name="twitter:site"
                content={siteURLShare + location.pathname}
              />

              <meta
                name="og:description"
                content={`Listagem de projectos integradores (${data.type})`}
              />
              <meta
                name="description"
                content={`Listagem de projectos integradores (${data.type})`}
              />
              <meta
                name="twitter:description"
                content={`Listagem de projectos integradores (${data.type})`}
              />

              {/*data.image!==null && (
                        <>
                        <meta name="twitter:image" content={data.image}/>
                        <meta name="og:image" content={data.image}/>
                        </>
                    )*/}
              <meta
                name="twitter:creator"
                content="Agrupamento de Escolas de Monte da Ola"
              />
            </Helmet>
          )}

          {loading === false &&
            data.map((item, index) => {
              let stripedHtml = "";
              if (item.body !== null) {
                stripedHtml = item.body.replace(/<[^>]+>/g, ""); //.substring(0,80);
                if (stripedHtml.length > 75)
                  stripedHtml = stripedHtml.substring(0, 75) + "...";
                else stripedHtml = stripedHtml.substring(0, 75);
              }

              return (
                <div
                  className="col-12 col-sm-6 col-md-6 col-lg-4 card-col"
                  key={index}
                >
                  <div
                    className="card-thin"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(item.url);
                    }}
                  >
                    {item.image_lst !== null && (
                      <div
                        className="img-over"
                        style={
                          {
                            /*maxHeight: "21.875rem", height: "100%"*/
                          }
                        }
                      >
                        <img
                          className="card-img-top"
                          style={
                            {
                              /*maxHeight: "21.875rem", height: "100%"*/
                            }
                          }
                          src={item.image_lst}
                          alt={item.title}
                        />
                        <div className="overlay-img">
                          <img
                            className="card-img-top"
                            src={overlay}
                            alt="Card image cap"
                          />
                        </div>
                      </div>
                    )}
                    {item.image_lst === null && (
                      <div
                        className="img-over"
                        style={
                          {
                            /*maxHeight: "21.875rem", height: "100%"*/
                          }
                        }
                      >
                        <img
                          className="card-img-top"
                          style={
                            {
                              /*maxHeight: "21.875rem", height: "100%"*/
                            }
                          }
                          src={imagem_default_lst}
                          alt={item.title}
                        />
                        <div className="overlay-img">
                          <img
                            className="card-img-top"
                            src={overlay}
                            alt="Card image cap"
                          />
                        </div>
                      </div>
                    )}

                    <div className="card-body news" style={{ height: "270px" }}>
                      <h5 className="card-title">{item.title}</h5>

                      <p className="card-text" dangerouslySetInnerHTML={{ __html: stripedHtml}} />
                      <div className="row justify-content-center">
                        <div className="col-12 lermais">
                          {item.body !== null && (
                            <LinkLerMais
                              href={item.url}
                              className="btn btn-new1"
                            >
                              <h4>Ler Mais</h4>
                            </LinkLerMais>
                          )}
                          {item.body === null && (
                            <LinkLerMais
                              href={item.link}
                              target="_blank"
                              className="btn btn-new1"
                            >
                              <h4>Ler Mais</h4>
                            </LinkLerMais>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <div className="container detalhe">
        <div className="row justify-content-center">
          <div
            className="col-12 col-sm-12 justify-content-center"
            style={{ display: "flex", marginTop: "4rem" }}
          >
            <button
              id="btn-voltar1"
              type="button"
              style={{ display: "flex", marginLEft: "40%" }}
              className="btn btn-voltar"
              onClick={(e) => {
                navigate(-1);
              }}
            >
              Voltar
            </button>
          </div>
        </div>
      </div>
      {/*}<div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-6 col-sm-2 vermais">
                    <a href="noticias.html"><ButtonkLerMais type="button" className="btn btn-big-lermais">Ver Mais</ButtonkLerMais></a>
                </div>
            </div>
        </div> 
    */}
    </section>
  );
}
