import React,{ useState,useEffect } from 'react';
import BreadCrumbs from '../../components/BreadCrumbs';

import img_folha_navegacao_dir from '../../images/svg/folha_navegacao_dir.svg';
import img_face_1 from '../../images/orgaos/1.png';
import './conselhogeral.css';
import img_exames from '../../images/avaliacao/exames.jpg';


import api, {siteURLShare} from '../../services/api';

import Loading from '../../components/Loading';
import {Helmet} from "react-helmet-async";
import { useLocation } from 'react-router-dom';

import profile_default from '../../images/images_default/profile_default.png';


export default function ConselhoGeral(){
    const location= useLocation();
    const [breadCrumbsData,setBreadCrumbsData]= useState([
        {
            nome: "Home",
            url: "/"
        },
        {
            nome:"Agrupamento",
            url:null
        },
        {
            nome:"Conselho Geral",
            url: "/conselho-geral"
        }
    ]);

    const [loading,setLoading] = useState(true);
    const [data,setData]=useState([]);

    useEffect(() => {

        async function loadData() {

            const response = await api.get('conselho/all');
            //debugger;
            //console.log(response.data)
            if (response.data.length > 0) {
                setData(response.data);
                
            }
            setLoading(false);
        }

        loadData();
        window.scrollTo(0, 0)
    }, []);
    return(
    <section className ="content">        
        <div className="container detalhe">    
            <BreadCrumbs data={breadCrumbsData}/>
        </div>
        {/* Titulo */}
        <div className="container detalhe">
            <div className="row ligacao justify-content-left">
                <div className="col-12">
                    <h1>Conselho Geral</h1>
                    <div className="row justify-content-left">
                        <div className="col-2">
                            <hr/>
                        </div>    
                    </div>
                </div>
            </div>
        </div>
    
        {/* corpo detalhe */}
        
        <div className="container detalhe">
        {loading===true &&(
            <div className="row">    
                <div className="col-12">
                    <Loading></Loading>
                </div>
            </div>    
        )}
        {loading===false &&(
        <div>
            <Helmet>
                <title>{`Agrupamento de Escolas de Monte da Ola ${'>'} Conselho Geral`}</title>
                <meta name="description" content={data.map((item,index)=>{
                            return(
                                `${item.name},`
                            );  
                        })}/>
                <meta name="keywords" content={`Website do Agrupamento de Escolas de Monte da Ola, Conselho Geral`}/>
                {/*facebook*/}
                <meta name="og:title" content={`Conselho Geral`}/>
                <meta name="og:url" content={siteURLShare+location.pathname} />
                <meta name="og:description" content={`Constituição do Conselho Geral do Agrupamento de Escolas de Monte da Ola`}/>
                <meta name="og:type" content="website"/>
                {/*twitter*/}
                <meta name="twitter:card" content="Conselho Geral Agrupamento de Escolas de Monte da Ola"/>
                <meta name="twitter:title" content={`Conselho Geral`}/>
                <meta name="twitter:description" content={`Constituição do Conselho Geral do Agrupamento de Escolas de Monte da Ola`}/>
                <meta name="twitter:site" content={siteURLShare+location.pathname}/>
                <meta name="twitter:creator" content="Agrupamento de Escolas de Monte da Ola"/>
            </Helmet>    
            <div className="row ligacao" style={{marginTop:"2rem", marginBottom: "3rem"}}>
                <div className="col-12 name">
                    <h2>Constituição do Conselho Geral do Agrupamento de Escolas de Monte da Ola</h2>
                </div>    
            </div>
            <div className="row">
                <div className="col-12" style={{marginBottom:"2rem"}}>
                    {/* conselho geral */}
                    <div className="row" style={{marginBottom:"2rem"}}>
                    {/*inicio*/}
                    {data[0].map((item,index)=>{
                        return(
                            <div className="row col-12 col-md-6 cartao-face" key={index}>
                                <div className="conselho-face">
                                    <div style={{ display: "block", float: "left", position: "relative", margin: "0px 20px 0px 0px" }}>
                                        {item.image!==null &&(
                                            <img className="img-thumbnail"
                                            src={item.image} alt={item.person} />
                                        )}
                                        {item.image==null &&(
                                            <img className="img-thumbnail"
                                            src={profile_default} alt={item.person} />
                                        )}
                                        
                                        <div className="img-overlay">
                                            <img className="img-face" src={img_folha_navegacao_dir} alt="img-face" />
                                        </div>
                                    </div>
                                    <p>
                                        <span className="conselho-name">{item.position}</span><br />
                                        <span className="conselho-cargo">{item.person}</span>
                                    </p>
                                </div>
                            </div>
                            );
                    })}                


                    </div>
                    {/* /conselho geral */}

                    {/* texto + imagem */}
                    <div className="row">
                        <div className="col-12 col-sm-7 col-md-8 col-lg-8 artigo-info">
                            <h2 className="representantes">Representantes do Agrupamento</h2>
                            <div  dangerouslySetInnerHTML={{ __html:data[1].body}}>
                            </div>
                        </div>
                       
                        {/*img do item */}
                        {data[1].image!==null &&(
                            <div className="col-12 col-sm-3 col-md-4 col-lg-4">
                                <div className="image-artigo">
                                    <img src={data[1].image} className="d-block w-100" alt="img-m" />
                                </div>
                            </div>
                        )}
                        
                        {/* /img do item */}
                    </div>
                    {/* /texto + imagem */}
                </div>
                
            </div>
        </div>    
        )}
            
        </div>
    </section>
    );
}