import React,{useEffect,useState} from "react";
import { useParams,useNavigate, Link,useLocation } from "react-router-dom";
import BreadCrumbs from "../../components/BreadCrumbs";
import Loading from '../../components/Loading'


import api,{siteURLShare,mseErroComunicacao} from '../../services/api';
import {Helmet} from "react-helmet-async";

import { toast } from 'react-toastify';

export default function PlanoAnualAtividades(){
    const location=useLocation();
    const navigate = useNavigate();
    const [data,setData]= useState([]);
    const [loading,setLoading]= useState(true);
    const [breadCrumbsData,setBreadCrumbsData]= useState([
        {
            nome: "Home",
            url: "/"
        }
        
    ]);
    useEffect(() => {

        async function loadData() {
            
            //const response = 
            await api.get(`/paa/all`).then(response=>{
                //console.log("response",response,typeof(response.data) )
                if(typeof(response.data)=='object' && response.data.status_code===404 ){
                    
                    alert("404")//setError404(true);
                    
                }
                else if (response.data!==null) {
                    //setTexto(response.data[0]);
                    setData(response.data);
                    //console.log("resposta completa",response.data);
                    setBreadCrumbsData([
                        {
                            nome: "Home",
                            url: "/"
                        },{
                            nome: "Plano Anual de Atividades",
                            url: response.data.url
                        }
                        
                    ]);
                }
                setLoading(false);

            }).catch(err=>{
                //toast.error(mseErroComunicacao);
                setTimeout(function(){
                    navigate("/erro-comunicacao");
                },5000);
            });
            

            
        }

        loadData();
        

        

        window.scrollTo(0, 0)
    }, []);
    return(
        <section className="content">
                
                {loading===false && (  
                <>
                <Helmet>
                <title>{`Agrupamento de Escolas de Monte da Ola ${'>'} PPM - Plano Anual de Atividades`}   </title>
                <meta name="description" content={`${data.body.replace(/<[^>]+>/g, '').substring(0,200)}`}/>
                <meta name="keywords" content={`Website Agrupamento de Escolas de Monte da Ola, PPM - Plano Anual de Atividades`}/>
                {/*facebook*/}
                <meta name="og:title" content={data.title}/>
                <meta name="og:url" content={siteURLShare+location.pathname} />
                <meta name="og:description" content={`${data.body.replace(/<[^>]+>/g, '').substring(0,200)}`}/>
                
                <meta name="og:type" content="website"/>
                {/*twitter*/}
                <meta name="twitter:card" content={`Agrupamento de Escolas de Monte da Ola ${'>'} PPM - Plano Anual de Atividades`}/>
                <meta name="twitter:title" content={`Agrupamento de Escolas de Monte da Ola ${'>'} PPM - Plano Anual de Atividades`}/>
                <meta name="twitter:description" content={`${data.body.replace(/<[^>]+>/g, '').substring(0,200)}`}/>
                <meta name="twitter:site" content={siteURLShare+location.pathname} />
                {/*data.image!==null && (
                    <>
                    <meta name="twitter:image" content={data.image}/>
                    <meta name="og:image" content={data.image}/>
                    </>
                )*/}
                <meta name="twitter:creator" content="Agrupamento de Escolas de Monte da Ola"/>
            </Helmet>
                    <div className="container detalhe">
                        <BreadCrumbs data={breadCrumbsData} />
                    </div>

                    <div className="container detalhe">
                        <div className="row ligacao justify-content-left">
                            <div className="col-12">
                                <h1>Plano Anual de Atividades</h1>
                                <div className="row justify-content-left">
                                    <div className="col-2">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row tabela-titulo" style={{ marginTop: "4rem" }}>
                            <div className="col-12 mt-10">
                                <h3>{data.title}</h3>
                                <p dangerouslySetInnerHTML={{__html:data.body}}></p>
                            </div>
                        </div>
                    </div>
                   <div className="container">     
                        <div className="row justify-content-center">
                            <div className="col-12" style={{/*background:"orange"*/}} dangerouslySetInnerHTML={{__html: "<iframe width='100%' height='100%' style='min-height:1486px;' src='"+data.link+"' />"}}>
                                

                            </div>
                        </div>
                    </div>
                </>
                )}          
                
    
    

          
    
            {/* Titulo */}
        
                        
                        
                      

                        
                 
            
        </section>
    );
}