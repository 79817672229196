
import React,{useEffect,useState} from "react";
import image1 from '../../../../images/image1.png';
import image2 from '../../../../images/image2.png';
import image3 from '../../../../images/image3.png';

import img_logo_norte2020 from '../../../../images/logos/logo-norte2020.png';
import img_logo_teip from '../../../../images/logos/logo-teip.png';

import './zona1slider.css'
import folha_esq from '../../../../images/svg/folha_navegacao_esq.svg';
import folha_dir from '../../../../images/svg/folha_navegacao_dir.svg';



import api,{siteURL,apiEndpoint,mseErroComunicacao} from "../../../../services/api";

import loading from'../../../../components/Loading'
import Loading from "../../../../components/Loading";

import {Helmet} from "react-helmet-async";


import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

export default function Zona1Slider() {
    const navigate = useNavigate();
    
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)
    useEffect(() => {

        async function loadData() {

            //const response = 
            await api.get('slides/all').then(response=>{
                if (response.data.length != 0) {
                    //tentou aceder com um id que nao existe, navegue para a home
                    setData(response.data);
                }
                setLoading(false);
            }).catch(err=>{
                //toast.error(mseErroComunicacao);
                setTimeout(function(){
                    navigate("/erro-comunicacao");
                },5000);
            });
            //debugger;
            //console.log(response.data)
            
        }

        loadData();
    }, []);

    return (
        <section>
            {loading === true && (
                <Loading/>
            )}

            {loading === false && (data.length > 0) &&(
                <>                
                <Helmet>
                    <title>Agrupamento de Escolas de Monte da Ola</title>
                    <meta name="description" content="Website do Agrupamento de Escolas de Monte da Ola"/>
                    <meta name="keywords" content="Website Agrupamento de Escolas de Monte da Ola"/>
                    {/*facebook*/}
                    <meta name="og:title" content="Agrupamento de Escolas de Monte da Ola"/>
                    <meta name="og:url" content={siteURL} />
                    <meta name="og:description" content="Website do Agrupamento de Escolas de Monte da Ola"/>
                    <meta name="og:image" content={data[0].image}/>
                    <meta name="og:type" content="website"/>
                    {/*twitter*/}
                    <meta name="twitter:card" content="Agrupamento de Escolas de Monte da Ola"/>
                    <meta name="twitter:title" content="Website do Agrupamento de Escolas de Monte da Ola"/>
                    <meta name="twitter:description" content="Website do Agrupamento de Escolas de Monte da Ola"/>
                    <meta name="twitter:site" content={siteURL}/>
                    <meta name="twitter:image" content={data[0].image}/>
                    <meta name="twitter:creator" content="Agrupamento de Escolas de Monte da Ola"/>
                </Helmet>


                <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">

                        {data.map((item,index) => {
                            let active='';
                            if(index==0){active='active'; }
                            //else return;
                            

                            return (
                                <div className={`carousel-item ${active}`} key={index}>
                                    <div className="overlay">
                                        {(item.image.length > 0) && (
                                            <img src={item.image} className="d-block w-100" alt={item.title1} />

                                        )}
                                    </div>
                                    <div className="carousel-caption">
                                        <div className="projeto-teip" dangerouslySetInnerHTML={{ __html: item.body }}>

                                        </div>
                                    </div>
                                </div>
                            );
                        })}


                        <button className="carousel-control-prev" style={{ backgroundImage: `url(${folha_esq})` }} type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev"></button>
                        <button className="carousel-control-next" style={{ backgroundImage: `url(${folha_dir})` }} type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next"></button>
                    </div>
                </div>
                </>

            )}


        </section>

    );
}