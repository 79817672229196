import React from "react";
import { useState,useEffect } from "react/";

import BreadCrumbs from "../../components/BreadCrumbs";

import './escolas.css'
import { useLocation, useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet-async";
import api,{apiEndpoint,siteURL,siteURLShare,mseErroComunicacao} from "../../services/api";
import Loading from '../../components/Loading';
import Erro from '../../pages/Erro';
import { toast } from 'react-toastify';
export default function Escolas(){
    
    const location=useLocation();
    const navigate= useNavigate();
    const [breadCrumbsData,setBreadCrumbsData]= useState([
        {
            nome: "Home",
            url: "/"
        },
        {
            nome:"Agrupamento",
            url:null
        },
        {
            nome:"Escolas",
            url: "/escolas"
        }
    ]);
    const [loading,setLoading] = useState(true);
    const [data,setData]=useState([]);


    const [dataPagina,setDataPagina] = useState({});
    const [dataEscolas,setDataEscolas] = useState([]);
    useEffect(() => {

        async function loadData() {

            //const response = 
            await api.get('escola/detalhe').then(response=>{
                var data1Pagina=response.data[0]
                //console.log("data_escolas=",response.data)
                var data_escolas=response.data.splice(2);
                
               // console.log("data_escolas=",data_escolas)
                setDataPagina(data1Pagina);
                setDataEscolas(data_escolas[0])
                //debugger;
                //console.log(data_escolas[0].offers)
                setLoading(false);
            }).catch(err=>{
                //toast.error(mseErroComunicacao);
                setTimeout(function(){
                    navigate("/erro-comunicacao");
                },5000);
            });
            
           
        }

        loadData();
        window.scrollTo(0, 0)
    }, []);
    /*if(error404 ===true){
       
        return(
            <>
            <Erro></Erro>
            </>
        );
    }*/
    return(
<section className ="content">        
    <div className="container detalhe">    
        <BreadCrumbs data={breadCrumbsData}/>
    </div>
    
    
    {/* Titulo */}
    <div className="container detalhe">
        <div className="row ligacao justify-content-left">
            <div className="col-12">
                <h1>Escolas</h1>
                <div className="row justify-content-left">
                    <div className="col-2">
                        <hr/>
                    </div>    
                </div>
            </div>
        </div>
    </div>
    {loading===true &&(

<div className="container detalhe">
<div className="row ligacao justify-content-left">
    <div className="col-12">
       <Loading></Loading>
    </div>
</div>
</div>
    )}
    {loading===false &&(
<>
<Helmet>
        <title>{`Agrupamento de Escolas de Monte da Ola ${'>'} Escolas`}</title>
        <meta name="description" content={data.map((item,index)=>{
                      return(
                          `${item.name},`
                      );  
                })}/>
        <meta name="keywords" content={`Website do Agrupamento de Escolas de Monte da Ola,Escolas`}/>
        {/*facebook*/}
        <meta name="og:title" content={`Escolas`}/>
        <meta name="og:url" content={siteURLShare+location.pathname} />
        <meta name="og:description" content={dataPagina.body}/>
        <meta name="og:type" content="website"/>
        {/*twitter*/}
        <meta name="twitter:card" content="Agrupamento de Escolas de Monte da Ola"/>
        <meta name="twitter:title" content={`Escolas`}/>
        <meta name="twitter:description" content={dataPagina.body}/>
        <meta name="twitter:site" content={siteURLShare+location.pathname}/>
        <meta name="twitter:creator" content="Agrupamento de Escolas de Monte da Ola"/>
    </Helmet>
        <div className="container detalhe">
            <div className="row">
                <div className="col-sm-12 col-lg-10  artigo-info tabela-nome">
                    {/*corpo de texto do item inicio*/}
                    <span>{dataPagina.body}</span>
                    <a href="https://drive.google.com/file/d/1v5bQoGC6uLp0I1BIaSC1tB1chAwZZCps/view" target="blank">
                        <h3 style={{marginTop: "4rem",marginBottom: "2rem"}}>Veja aqui as distâncias entre as escolas do agrupamento</h3></a>
                </div>
            </div>

        </div>
</>        
    )}    
    
    {/*ICICIO tabela  */}
    {loading===false && (
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 tabela">
                            <table className="table" border="0">
                                <thead>
                                    <tr className="linha">
                                        <th width="30%"></th>
                                        <th>Pré Escolar</th>
                                        <th>1º Ciclo</th>
                                        <th>2º e 3º Ciclos</th>
                                        <th>Secundário</th>
                                        <th>Vocacional</th>
                                        <th>PIEF</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {dataEscolas.map((item_,index_)=>{

                                    return(
                                        <tr className="linha" key={"row"+index_}>
                                            <td className="left">
                                            {item_.link!==null &&(
                                                <a href={item_.link} target="_blank">{item_.name}</a>
                                            
                                            )}
                                            {item_.link===null &&(
                                                <>
                                                {item_.name}
                                                </>
                                            )}
                                            </td>
                                            <td data-th="Pré Escolar: "><div>{item_.offers["Pré Escolar"]==true?"X":"--"}</div></td>
                                            <td data-th="1º Ciclo: "><div>{item_.offers["1º Ciclo"]==true?"X":"--"}</div></td>
                                            <td data-th="2º e 3º Ciclos: "><div>{item_.offers["2º e 3º Ciclos"]==true?"X":"--"}</div></td>
                                            <td data-th="Secundário: "><div>{item_.offers["Secundário"]==true?"X":"--"}</div></td>
                                            <td data-th="Vocacional: "><div>{item_.offers["Vocacional"]==true?"X":"--"}</div></td>
                                            <td data-th="PIEF: "><div>{item_.offers["PIEF"]==true?"X":"--"}</div></td>
                                        </tr>
                                    );


                                })}
                                
                                  
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
    )}
</section>
    );
}