import {BrowserRouter,Routes, Route} from 'react-router-dom';
import './contexts/global'
import Header from './components/Header';
import Footer from './components/Footer';
import Share from './components/Share';
import Home from './pages/Home';
import Erro from './pages/Erro';
import AvaliacaoExternaListagem from './pages/Alunos/AvaliacaoExternaListagem';
import AvaliacaoExternaDetalhe from './pages/Alunos/AvaliacaoExternaDetalhe';    
import OrgaosDeGestao from './pages/OrgaosGestao'; 
import ConselhoGeral from './pages/ConselhoGeral';
import Escolas from './pages/Escolas';
import PlanoPlurianual from './pages/PlanoPlurianual';

import ResultadosPesquisa from './pages/ResultadosPesquisa';


import ActividadesDetalhe from './pages/Actividades/ActividadesDetalhe/';

import NoticiaAnuncioDetalhe from './pages/NoticiaAnuncio/NoticiaAnuncioDetalhe'
import NoticiaAnuncioListagem from './pages/NoticiaAnuncio/NoticiaAnuncioListagem'

import ItemDetalhe from './pages/ItemDetalhe'
import AgrupamentoDetalhe from './pages/Arupamento/AgrupamentoDetalhe';

import ItemContatos from './pages/Arupamento/ItemContatos';

import PlanoAnualAtividades from './pages/PlanoAnualAtividades/index';

import PPMProjeto from './pages/PPMProjecto'
import ConselhoPedagogico from './pages/ConselhoPedagogico';

import ItemFooter from './pages/ItemFooter/';
import ListaLinks from './pages/ListaLinks/';
import {Helmet} from "react-helmet-async";


import ListagemProjetosIntegradores from './pages/ProjetosIntegradores/ListagemProjetosIntegradores';
import DetalheProjetoIntegrador from './pages/ProjetosIntegradores/DetalheProjetoIntegrador';

import ErroComunicacao from './pages/ErroComunicacao';

import { ToastContainer, toast } from 'react-toastify';




//import {RedirectContextProvider} from './contexts/global';
//para anuncios e informacoes
const Rotas = ()=>{
    
    return(
        <BrowserRouter>
            <Header />
            
            <Routes>
                <Route exact path="/" element={<Home />} />
                {/* Definitivos ININCIO.----------------------------------------------- */}
                {/*ActividadesDelhalhe */}
                <Route exact path="/atividade/:slug" element={<ActividadesDetalhe />} />
                <Route exact path="/pesquisa">
                    <Route exact path=":pesq" element={<ResultadosPesquisa/>}/>
                    <Route exact path=":pesq/:page" element={<ResultadosPesquisa/>}/>
                    <Route exact path=":pesq/:page/:page_size" element={<ResultadosPesquisa/>}/>
                </Route>


                <Route exact path="/atividade/:slug" element={<ActividadesDetalhe />} />
                {/*exemplo listagem HELMET INCLUIDO*/}
                <Route exact path="/avaliacao-externa" element={<AvaliacaoExternaListagem />} />
                {/*exemplo detalhe HELMET INCLUIDO*/}
                <Route exact path="/avaliacao-externa/:slug" element={<AvaliacaoExternaDetalhe />} />
                {/* MENU ALUNOS  HELMET INCLUIDO*/}
                <Route exact path="/alunos/ofertas-educativas" element={<ItemDetalhe nodata={true}/>} />
                <Route exact path="/alunos/equipa-tecnica-especializada" element={<ItemDetalhe nodata={true}/>} />
                {/*<Route exact path="/alunos/manuais-escolares" element={<ItemDetalhe />} />*/}
                {/* novo componente para listagesm de ficheiuros (links) ------------------------
                -passado o endpoin 
                -passado o title
                
                */}
                {/* HELMET INCLUIDO*/}
                <Route exact path="/alunos/manuais-escolares"
                 element={<ListaLinks apiendpoint="manuais/all" title="Manuais Escolares" />} />
                
                {/**------------------------------------------------------------------------------ */}

                {/*#MENU*/}
                {/*com grid HELMET INCLUIDO*/}
                <Route exact path="/agrupamento/escolas" element={<Escolas />} />    

                {/* item concelho geral HELMET INCLUIDO*/}
                <Route exact path="/agrupamento/conselho-geral" element={<ConselhoGeral />} />
                {/* item orgaos de gestao HELMET INCLUIDO*/}
                <Route exact path="/agrupamento/orgaos-gestao" element={<OrgaosDeGestao />} />
                {/* HELMET INCLUIDO */}
                <Route exact path="agrupamento/conselho-pedagogico" element={<ConselhoPedagogico />} />
                {/* HELMET INCLUIDO */}
                <Route exact path="/agrupamento/documentos-orientadores"
                 element={<ListaLinks apiendpoint="documentos/all" title="Documentos Orientadores"/>} />
                {/* HELMET INCLUIDO */}
                <Route exact path="/agrupamento/contatos" element={<ItemContatos />} />
                
                {/* item Plano Plurianual de Melhoria  */}
                {/* HELMET INCLUIDO */}
                <Route exact path="/ppm/planoplurianual" element={<PlanoPlurianual />} />
                {/* HELMET INCLUIDO */}
                <Route exact path="/ppm/:slug" element={<PPMProjeto />}></Route>
                
                {/*HELMET INCLUIDO itemDetalhe era inicialmente para ser utilizado apenas no menu alunos, vou coloca-lo na raiz */}
                <Route exact path="/bibliotecaescolar/plano-de-acao-da-be" element={<ItemDetalhe />} />

                
                {/* Helmet Incluido */}
                <Route exact path="/plano-anual-atividades" element={<PlanoAnualAtividades />} />
                {/* Helmet Incluido */}
                <Route exact path=  "/associacaopais/contactos-das-associacoes-de-pais" element={<ItemDetalhe nodata={true}/>} />
                {/* Helmet Incluido */}
                <Route exact path="/projetos-integradores-categoria/:type_slug" element={<ListagemProjetosIntegradores />} />
                {/* Helmet Incluido */}
                <Route exact path="/projetos-integradores/:slug" element={<DetalheProjetoIntegrador />} />


                {/*exemplo listagem */}
                <Route exact path="/noticia-anuncio" element={<NoticiaAnuncioListagem />} />
                {/*exemplo detalhe*/}
                <Route exact path="/noticia-anuncio/:slug" element={<NoticiaAnuncioDetalhe />} />

                <Route exact path="/concursos" element={<NoticiaAnuncioListagem />} />
                
                <Route exact path="/concurso/:slug" element={<NoticiaAnuncioDetalhe />} />
                
                

                
                
                

                
                
                

                
                
                {/* ta em erro
                <Route exact path="/pagina/agrupamento/documentos-orientadores" element={<AgrupamentoDetalhe />} />
                */}
                
                 
               
                {/*footer é preciso um componente à parte pois nao é standard 11.03.2022*/}
                <Route exact path="/footer/politica-de-privacidade" element={<ItemFooter />} />
                
                


               {/*}  Definitivos FIM -----------------------------------------------------*/}
                
                
                
            {/*INICIO testes design*/}
                
                
                
                

               
            {/*FIM testes design*/}
               
                    <Route exact path="/erro-comunicacao" element={<ErroComunicacao />} />
                    <Route exact path="*" element={<Erro />} />
            </Routes>
            <Footer />
            <ToastContainer></ToastContainer>  
        </BrowserRouter>
        
    );

}

export default Rotas;