import React from 'react'
;
import './loading.css';

export default function Loading(){


    return(
        <div className="loadingio-spinner-spinner-1iwpfj3vs5u"><div className="ldio-3bxzgjuwwta">
<div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
</div></div>
    )
}