import React from 'react';
import './erro.css';
import {Link} from 'react-router-dom';

import img_404 from '../../images/404.png';

import { useNavigate } from 'react-router-dom';

export default function Erro() {

  const navigate = useNavigate();
  return (
    
    <section>
        <div className="container erro">
          <div className="row">
              
               <div className="col-12 col-md-6">
                  <img src={img_404} className="img-fluid"></img>
              </div>
           
              <div className="col-12 col-md-6">
              <h1 className="d-none d-md-block">404</h1>
                               
                    <h3>A página que procura não existe ou foi eliminada recentemente.</h3>                  
                  
                {/*<Link className="btn btn-erro" to="/">Voltar</Link> */}
                <button className="btn btn-erro" onClick={() => navigate(-1)}>Voltar</button>
                  
              </div>
            </div>
          </div>
        
      </section>
    );
}