
import React from "react";
import { useEffect, useState } from "react";
import './cookiebanner.css'

export default function CookieBanner(){
    
    
    const [cookiesAccepted,setCookiesAccepted]= useState(false)
    
    
    function checkCookie(){
        let value = getCookie("acceptedcookies");
        if (value === "accepted") {
            setCookiesAccepted(true);
        } else {
            value="notaccepted"
            setCookie("acceptedcookies", value, 365);
            setCookiesAccepted(false);          
          
        }
    }


    function setCookie(cname, cvalue, exdays) {

        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
      
    function getCookie(cname) {
      
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }

    function hadleAcceptCookies(){
        let value = "accepted";
        setCookie("acceptedcookies", value, 365);
        checkCookie();
    }

    

    

    useEffect(()=>{
        
        checkCookie();
    },[cookiesAccepted]);


    return (
    <div id="cookie-banner" className={`alert alert-light text-center mb-0 ${cookiesAccepted===true ? 'd-none':''}`} role="alert">
        <div className="container">
        <div className="row justify-content-center">
                <div className="col-8 col-md-10 " style={{paddingTop:"0.5rem"}}>
                Esta página está a usar cookies. Ao continuar está a aceitar a sua utilização e concorda com a nossa <a href="/footer/politica-de-privacidade#privacidade">política de privacidade.</a>
                </div>
                <div className="col-4 col-md-2" style={{display:"flex", alignItems:"center"}}>
                    <button type="button" className="btn btn-primary btn-sm ml-3 btn-private" onClick={()=>{hadleAcceptCookies();}}>
                    Continuar
                    </button>
                </div>    
        </div> 
        </div>
           
    </div>
    );
}