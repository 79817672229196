import React, { useState, useEffect } from "react";

///import YouTube from 'react-youtube';

import ReactPlayer from "react-player";

import YouTube from "@u-wave/react-youtube";
import Loading from "../../../../components/Loading";

import api, {
  apiEndpoint,
  siteURL,
  mseErroComunicacao,
} from "../../../../services/api";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
export default function () {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [video, setVideo] = useState(null);
  useEffect(() => {
    function youtube_parser(url) {
      var regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      let id = match && match[7].length == 11 ? match[7] : false;
      //alert(id);
      return id;
    }
    async function loadData() {
      await api
        .get("/ligacoes/ligacao/Escola-e-Localizacoes")
        .then((response) => {
          if (response.data != null) {
            setVideo(youtube_parser(response.data.link));
          }

          setLoading(false);
        })
        .catch((err) => {
          setLoading(false)
          //toast.error(mseErroComunicacao);
          /*setTimeout(function () {
            navigate("/erro-comunicacao");
          }, 5000);*/
        });
    }

    loadData();
  }, []);
  return (
    <section>
      {loading === true && <Loading />}
      {video !== null && (
        <div
          className="ratio"
          style={{ paddintBottom: "-1px", paddingTop: "45.15%" }}
        >
          <ReactPlayer
            id="player"
            width="100%"
            height="100%"
            url={`https://www.youtube.com/watch?v=${video}`}
            config={{ youtube: { playerVars: { origin: siteURL } } }}
            playing={true}
            muted={true}
            loop={true}
          />
          {/*<iframe 
                       src={`https://www.youtube.com/embed/${video}?autoplay=1&mute=1&fs=1&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=${siteURL}`}
                       title="Escolas" 
                       allowFullScreen
                       ></iframe>*/}
        </div>
      )}
      {loading === false && video === null && (<div style={{height:'120px',backgroundColor:'white'}}/>)} 
    </section>
  );
}
