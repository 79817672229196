import React,{useEffect,useRef,useState} from "react";


import { useNavigate,useLocation, useParams } from "react-router-dom";

import BreadCrumbs from "../../components/BreadCrumbs";

import Loading from '../../components/Loading'
//import './detalhe.css';
import img_exames from '../../images/avaliacao/exames.jpg';
import api,{apiEndpoint,siteURL, mseErroComunicacao} from "../../services/api";

import Erro from '../../pages/Erro'
import { toast } from 'react-toastify';
export default function ItemFooter(){
    //const { slug } = useParams();
    const location=useLocation();
    const navigate=useNavigate();
    /*const [breadCrumbsData,setBreadCrumbsData]= useState([
        {
            nome: "Footer",
            url: "/"
        }
        
    ]);*/
    const termosRef= useRef();
    const privacidadeRef= useRef();
    const executeScroll1 = () => termosRef.current.scrollIntoView()  
    const executeScroll2 = () => privacidadeRef.current.scrollIntoView()    
    // run this function from an event handler or an effect to execute scroll 
 

    const [dataPoiticaPrivacidade,setDataPoiticaPrivacidade]=useState([]);
    const [dataTermosServico,SetDataTermosServico]=useState([]);
    const[loading,setLoading]=useState(true)
    const[error404,setError404]=useState(false)
    const[hash,sethash]=useState(null)
      useEffect(() => {
         
        async function loadData() {

            sethash(location.hash)

            //console.log(location.pathname)
            //console.log("hash:",location.hash,"location.key:",location.key)
            //const response = 
            await api.get(`/item/politica-privacidade`).then(response=>{
                //console.log("response",response,typeof(response.data[0]) )
                if(typeof(response.data)=='object' && response.data.status_code===404 ){
                                
                    setError404(true);
                    
                }
                else if (response.data.length > 0) {
                    //1 NextPrevLink
                    setDataPoiticaPrivacidade(response.data[0]);
                    SetDataTermosServico(response.data[1]);
                    //console.log("data:",response.data)
                    
                //2
                /*setBreadCrumbsData(
                    [{
                        nome: "Home",
                        url: "/"
                    },
                    {
                        nome: "Alunos",
                        url: null
                    },
                    {
                        nome:response.data[0].title,
                        url: response.data[0].url
                    }             
                    ]);*/
                }
                setLoading(false);
            }).catch(err=>{
                //toast.error(mseErroComunicacao);
                setTimeout(function(){
                    navigate("/erro-comunicacao");
                },5000);
            });

            
            

           if(location.hash=="#termos"){
                executeScroll1();
           }else if(location.hash=="#privacidade"){
               executeScroll2();
           }     
            
        }

        loadData();
        

       // console.log(data)
    }, [location.hash]);
    

    if(error404 ===true){
       
        return(
            <>
            <Erro></Erro>
            </>
        );
    }

function tratadata(data){
    var options = {  day:'numeric',month: 'long',year:'numeric'};
    var date  = new Date(data);
    let data_str =date.toLocaleDateString("pt-pt",options).toString().replace(".","");
    options = {  month: 'long'};
    var mes1=date.toLocaleDateString("pt-pt",options).toString().replace(".","");
    var mes2=mes1.charAt(0).toUpperCase()+mes1.slice(1);
    //console.log("mes2=",mes2)
    
    data_str=data_str.replace(mes1,mes2);
     return data_str
}
    return(
<section className ="content">   
{loading===true && (<Loading></Loading>)} 

{loading===false && ( 
   <>
   {/*div className="container detalhe">    
        <BreadCrumbs data={breadCrumbsData}/>
</div>*/}
    {/* Titulo */}
    <div className="container detalhe">
        <div className="row ligacao justify-content-left">
            <div className="col-12">
                <h1 ref={privacidadeRef}>{dataPoiticaPrivacidade.title}</h1>
               {/* <p>{tratadata(dataPoiticaPrivacidade.publishDate)}</p>*/}
                <div className="row justify-content-left">
                    <div className="col-2">
                        <hr/>
                    </div>    
                </div>
            </div>
        </div>
    </div>

    {/* corpo detalhe */}
    <div className="container detalhe">
        <div className="row">
            <div className="col-12 col-sm-12 col-md-8 col-lg-8 artigo-info" dangerouslySetInnerHTML={{ __html:dataPoiticaPrivacidade.body}}>
                {/*corpo de texto do item inicio*/}
                
                 {/*corpo de texto do item fim*
                <button className="btn btn-share" type="button"></button>    */}               
            </div>
            {/*img do item */}
            {dataPoiticaPrivacidade.image!== null && dataPoiticaPrivacidade.image.length>0 &&(
                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="image-artigo">
                        <img src={dataPoiticaPrivacidade.image}  className="d-block w-100" alt="img-m" />
                    </div>
                </div>
            )}
            
            {/* /img do item */}
        </div>
    </div> 
                {/******Termos de serviço *********************** */}
                
           
        <div ref={termosRef} id="TermosServico" className="container detalhe">
        <div className="row ligacao justify-content-left">
            <div className="col-12">
                <h1>{dataTermosServico.title}</h1>
                {/*<p>{tratadata(dataTermosServico.publishDate)}</p>*/}
                <div className="row justify-content-left">
                    <div className="col-2">
                        <hr/>
                    </div>    
                </div>
            </div>
        </div>
    </div>       
    
{/* corpo detalhe */}

<div className="container detalhe">
        <div className="row">
            <div className="col-12 col-sm-12 col-md-8 col-lg-8 artigo-info" dangerouslySetInnerHTML={{ __html:dataTermosServico.body}}>
                {/*corpo de texto do item inicio*/}
                
                 {/*corpo de texto do item fim*
                <button className="btn btn-share" type="button"></button>    */}               
            </div>
            {/*img do item */}
            {dataTermosServico.image!== null && dataTermosServico.image.length>0 &&(
                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="image-artigo">
                        <img src={dataTermosServico.image}  className="d-block w-100" alt="img-m" />
                    </div>
                </div>
            )}
            
            {/* /img do item */}
        </div>
    </div> 
                {/******Termos de serviço *********************** */}
                
           
        

                 {/******Termos de serviço *********************** */}
            <div className="col-12 col-sm-12 justify-content-center" style={{display:"flex",marginTop:"4rem"}}>
                <button id="btn-voltar1" type="button" style={{display:"flex"}} className="btn btn-voltar" onClick={(e) => { navigate(-1); }}>
                    Voltar
                </button>
            </div>
       
</>
    )}
</section>
    );
}