import "./header.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import logo_aemo from "../../images/logos/logo_aemo.png";

import api, { mseErroComunicacao } from "../../services/api";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ItemFooter from "../../pages/ItemFooter";
export default function Header() {
  const location = useLocation();
  const navigate = useNavigate();

  const [pesq, setPesq] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [navbarExpanded, setNavbarExpanded] = useState(false);

  const [navbarStyle, setNavbarStyle] = useState({
    zIndex: "2",
    paddingBottom: "0px",
  });

  const [sugestoes, setSugestoes] = useState({});
  const [searchVisible, setSearchVisible] = useState(false);

  const [calendarioEscolar, setCalendarioEscolar] = useState({});

  //const [siae,setSiae] = useState({});
  //const [areaPrivada,setAreaPrivada]= useState({})
  const [menuDocentes, setMenuDocentes] = useState([]);
  const [loading, setLoading] = useState(true);

  const [menuConcursos, setMenuConcursos] = useState([]);
  const [menuBE, setMenuBE] = useState([]);
  const [showBE, setShowBE] = useState(false);

  const [menuProjetosIntegradores, setMenuProjetosIntegradores] = useState([]);

  const [menuPPM, setMenuPPM] = useState([]);
  useEffect(() => {
    //setNavbarExpanded(false);
    //console.log("useEffect")
    //console.log(location.pathname);
    //console.log(navbarExpanded);
    //console.log("eval=>"+evalPaddingNavbar());
    async function loadData() {
      //const responseCE = await api.get('/ligacoes/ligacao/calendario-escolar');
      await api
        .get("/ligacoes/calendario")
        .then((responseCE) => {
          setCalendarioEscolar(responseCE.data);
        })
        .catch((err) => {
          //toast.error(mseErroComunicacao);
          /*setTimeout(function () {
            navigate("/erro-comunicacao");
          }, 5000);*/
        });

      //console.log("#responseCE.data#",responseCE.data)
      /*
            const responseSIAE = await api.get('/ligacoes/ligacao/siae'); 
            setSiae(responseSIAE.data);
            const responseAP = await api.get('/ligacoes/ligacao/area-privada '); 
            setAreaPrivada(responseAP.data);*/
      await api
        .get("/ligacoes/all/docentes")
        .then((responseDocentes) => {
          setMenuDocentes(responseDocentes.data);
        })
        .catch((err) => {
          //toast.error(mseErroComunicacao);
          setTimeout(function () {
            navigate("/erro-comunicacao");
          }, 5000);
        });

      await api
        .get("/ligacoes/ligacao/sugestoes")
        .then((responsesugestoes) => {
          setSugestoes(responsesugestoes.data);
        })
        .catch((err) => {
          //toast.error(mseErroComunicacao);
          /*setTimeout(function () {
            navigate("/erro-comunicacao");
          }, 5000);*/
        });

      await api
        .get("/pagina/concursos")
        .then((responsemenuconcursos) => {
          setMenuConcursos(responsemenuconcursos.data);
        })
        .catch((err) => {
          //toast.error(mseErroComunicacao);

          setTimeout(function () {
            navigate("/erro-comunicacao");
          }, 5000);
        });

      await api
        .get("/ligacoes/all/bibliotecaescolar")
        .then((responsemenube) => {
          setMenuBE(responsemenube.data);
        })
        .catch((err) => {
          //toast.error(mseErroComunicacao);
          setTimeout(function () {
            navigate("/erro-comunicacao");
          }, 5000);
        });

      await api
        .get("/ppm/projetos")
        .then((responsemenuppm) => {
          setMenuPPM(responsemenuppm.data);
        })
        .catch((err) => {
          //toast.error(mseErroComunicacao);
          setTimeout(function () {
            navigate("/erro-comunicacao");
          }, 5000);
        });

      await api
        .get("/projetosintegradores/menu")
        .then((responsemenu_projectos_itegradores) => {
          setMenuProjetosIntegradores(responsemenu_projectos_itegradores.data);
        })
        .catch((err) => {
          //toast.error(mseErroComunicacao);

          setTimeout(function () {
            navigate("/erro-comunicacao");
          }, 5000);
        });

      await api
        .get("/item/bibliotecaescolar/plano-de-acao-da-be")
        .then((response) => {
          if (
            response.data[0].body !== null &&
            response.data[0].body !== "" &&
            response.data[0].state == "Publicada"
          ) {
            setShowBE(true);
          }
        })
        .catch((err) => {
          //toast.error(mseErroComunicacao);
        });
      //console.log("MENUPROJECTOS",responsemenu_projectos_itegradores.data)
    }

    loadData();
    setLoading(false);

    let navbar = document.getElementById("navbar1");
    navbar.addEventListener("show.bs.collapse", function () {
      //console.log("eveneto show bs collapse")
      setNavbarExpanded(true);
      //console.log(navbarExpanded);
      evalPaddingNavbar();
      //navbar.classList.add("expandido");
    });
    navbar.addEventListener("hide.bs.collapse", function () {
      //console.log("eveneto hide bs collapse")
      setNavbarExpanded(false);
      //console.log(navbarExpanded);
      evalPaddingNavbar();
      navbar.classList.remove("expandido");
    });
  }, [navbarExpanded]);

  /*
    function handleResize(){
        //setWindowWidth(window.innerWidth);
        
        let navbar=document.getElementById('navbar1')

        if(window.innerWidth>991 && navbarExpanded===true){               
            navbar.classList.remove("expandido");                
        }else if(window.innerWidth<991 && navbarExpanded===true){
            navbar.classList.add("expandido"); 
        }
        evalPaddingNavbar();
    }*/
  //window.addEventListener('resize', handleResize);

  /*function handleToggleNavBar(){
        
        setNavbarExpanded(!navbarExpanded);
        console.log(navbarExpanded);

        evalPaddingNavbar();
    }
*/
  function evalPaddingNavbar() {
    if (location.pathname === "/" && navbarExpanded === true) {
      setNavbarStyle({
        zIndex: "2",
        paddingBottom: "0px",
      });
    } else {
      setNavbarStyle({
        zIndex: "2",
        paddingBottom: "0rem",
        marginBottom: `${location.pathname === "/" ? "11rem" : "0"}`,
      });
    }
    //console.log(navbarStyle)
  }
  //
  function handleTogleSearch() {
    setSearchVisible(!searchVisible);
  }
  function processaPesquisa(e) {
    e.preventDefault();
    //alert(pesq)
    let p = pesq;
    if (p.length > 0) {
      //setPesq('')
      navigate("/pesquisa/" + encodeURIComponent(p) + "/1/10");
    }
  }
  function handleChangePesq(valor) {
    setPesq(valor);
  }
  /**/
  function addFocusToSearch() {
    document.getElementById("search1").classList.add("focus");
  }
  function removeFocusToSearch() {
    let el = document.getElementById("pesq1");
    //value=document.defaultView.getComputedStyle(el)["opacity"];

    document.getElementById("search1").classList.remove("focus");
  }
  function toggleFocus() {
    let el = document.getElementById("pesq1");
    //value=document.defaultView.getComputedStyle(el)["opacity"];

    if (document.getElementById("search1").classList.contains("focus")) {
      document.getElementById("search1").classList.remove("focus");
    } else {
      document.getElementById("search1").classList.add("focus");
    }
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      processaPesquisa(event);
    }
  }

  //animate in
  function animatePesquisaIn(e) {
    setSearchVisible(true);
    document.getElementById("pesq1").classList.add("animateIn");
    //width:440px
    //width: 450px;
    //left: -450px;
    //opacity:1
  }
  function animatePesquisaOut() {
    setSearchVisible(false);
    document.getElementById("pesq1").classList.remove("animateIn");
  }

  return (
    <header>
      <nav
        id="navbar1"
        className={`navbar navbar-expand-lg navbar-light ${
          location.pathname === "/" ? "bghome" : "bgpages"
        } ${navbarExpanded === true ? "expandido" : ""}`}
        style={navbarStyle}
      >
        <div
          className="container justify-content-between mt-2 mb-2"
          style={{ position: "relative" }}
        >
          <a className="navbar-brand" href="/">
            <img
              className="d-inline-block"
              src={logo_aemo}
              style={{ padding: "10px" }}
            />
          </a>
          <button
            onClick={() => {
              /*handleToggleNavBar();*/
            }}
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars" style={{ fontSize: "22px" }}></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {/* Alunos */}
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle btn"
                  href="#"
                  id="dropdownMenuButton"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>Alunos &nbsp;</span>
                  <i className="fas fa-chevron-down"></i>
                </a>
                <ul
                  className="dropdown-menu freedrop"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li>
                    <a className="dropdown-item" href="/avaliacao-externa">
                      Avaliação Externa
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="/alunos/ofertas-educativas"
                    >
                      Ofertas Educativas
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="/alunos/equipa-tecnica-especializada"
                    >
                      Equipa Técnica Especializada
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="/alunos/manuais-escolares"
                    >
                      Manuais Escolares
                    </a>
                  </li>

                  {loading === false && (
                    <li>
                      <a
                        className="dropdown-item"
                        href={calendarioEscolar.link}
                        target="_blank"
                      >
                        {calendarioEscolar.title}
                      </a>
                    </li>
                  )}
                </ul>
              </li>
              {/* Docentes */}
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle btn"
                  href="#"
                  id="dropdownMenuButton2"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span> Docentes &nbsp;</span>
                  <i className="fas fa-chevron-down"></i>
                </a>
                <ul
                  className="dropdown-menu freedrop"
                  aria-labelledby="dropdownMenuButton2"
                >
                  {loading === false && (
                    <>
                      {menuDocentes.map((item, index) => {
                        return (
                          <li key={index}>
                            <a
                              className="dropdown-item"
                              href={item.link}
                              target="_blank"
                            >
                              {item.title}
                            </a>
                          </li>
                        );
                      })}
                    </>
                  )}
                </ul>
              </li>
              {/* Menu */}
              <li className="nav-item dropdown dropdown-big">
                <a
                  className="nav-link dropdown-toggle btn"
                  href="#"
                  id="dropdownMenuButton3"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span> Menu &nbsp;</span>
                  <i
                    id="Menu1"
                    className="fas fa-bars"
                    style={{ fontSize: "22px" }}
                  ></i>
                  <i id="Menu2" className="fas fa-chevron-down"></i>
                </a>
                {/* Menu content */}
                <div
                  className="dropdown-menu dropdown-menu-long"
                  aria-labelledby="dropdownMenuButton3"
                >
                  <div className="row aux-fix-direction">
                    <div className="col">
                      <h3 className="menu-category">Agrupamento</h3>
                      <ul>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/agrupamento/escolas"
                          >
                            Escolas
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/agrupamento/conselho-geral"
                          >
                            Conselho Geral
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/agrupamento/orgaos-gestao"
                          >
                            Orgãos de Gestão
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/agrupamento/conselho-pedagogico"
                          >
                            Conselho Pedagógico
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/agrupamento/documentos-orientadores"
                          >
                            Documentos Orientadores
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/agrupamento/contatos"
                          >
                            Contatos
                          </a>
                        </li>

                        <li>
                          <a
                            className="dropdown-item"
                            href="/associacaopais/contactos-das-associacoes-de-pais"
                          >
                            Associações de Pais
                          </a>
                        </li>
                        {sugestoes != null && sugestoes != "" && (
                          <li>
                            <a
                              className="dropdown-item"
                              href={sugestoes.link}
                              target="_blank"
                            >
                              {sugestoes.title}
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className="col">
                      <h3 className="menu-category">TEIP</h3>
                      <ul>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/ppm/planoplurianual"
                          >
                            PPM - Plano Plurianual de Melhoria
                          </a>
                        </li>
                        {loading === false && (
                          <>
                            {menuPPM.map((item, index) => {
                              return (
                                <li key={index}>
                                  <a className="dropdown-item" href={item.url}>
                                    {item.title}
                                  </a>
                                </li>
                              );
                            })}
                          </>
                        )}
                      </ul>
                    </div>
                    <div className="col">
                      <h3 className="menu-category">Biblioteca Escolar</h3>
                      <ul>
                        {showBE === true && (
                          <li>
                            <a
                              className="dropdown-item"
                              href="/bibliotecaescolar/plano-de-acao-da-be"
                              target="_self"
                            >
                              Plano de Ação da BE
                            </a>
                          </li>
                        )}
                        {loading === false && (
                          <>
                            {menuBE.map((item, index) => {
                              if (item.link !== null && item.link !== "") {
                                return (
                                  <li key={index}>
                                    <a
                                      className="dropdown-item"
                                      href={item.link}
                                      target="_blank"
                                    >
                                      {item.title}
                                    </a>
                                  </li>
                                );
                              }
                            })}
                          </>
                        )}
                      </ul>
                    </div>
                    <div className="col">
                      <h3 className="menu-category">
                        PAA/Projetos Integradores
                      </h3>
                      <ul>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/plano-anual-atividades"
                          >
                            Plano Anual de Atividades
                          </a>
                        </li>
                        {/* -- MENU PROJECTOS INTEGRADORES INICIO */}
                        {menuProjetosIntegradores.map((item, index) => {
                          let _target = "_self";
                          let targetURL = "";
                          let tituloMenu = "";
                          if (item.TIPOMENU == "SINGLE") {
                            if (item.body === null || item.body === "") {
                              _target = "_blank";
                              targetURL = item.link;
                              tituloMenu = item.title;
                            } else {
                              _target = "_Self";
                              targetURL = item.url;
                              tituloMenu = item.title;
                            }
                          } else {
                            targetURL = `/projetos-integradores-categoria/${encodeURIComponent(
                              item.type_slug
                            )}`;
                            _target = "_self";
                            tituloMenu = item.type;
                          }
                          //console.log(tituloMenu,targetURL,_target)
                          return (
                            <li key={index}>
                              <a
                                className="dropdown-item"
                                href={targetURL}
                                target={_target}
                              >
                                {tituloMenu}
                              </a>
                            </li>
                          );
                        })}
                        {/* -- MENU PROJECTOS INTEGRADORES FIM */}
                      </ul>
                    </div>

                    <div className="col">
                      <h3 className="menu-category">
                        <a href="/concursos">Concursos</a>
                      </h3>
                      <ul>
                        {loading === false &&
                          menuConcursos.map((item, index) => {
                            //console.log("item.url_concurso",item.url_concurso)
                            return (
                              <li key={"li" + index}>
                                <a
                                  className="dropdown-item"
                                  href={item.url_concurso}
                                >
                                  {item.title}
                                </a>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* /Menu content */}
              </li>
              {/*}
        <li className='nav-item' style={{display:'flex', flexDirection:"row",alignItems:"center", width:"100%",justifyContent:"space-between"}}>
        
        <button  type="submit" onClick={(e)=>{handleTogleSearch();}} className="btn">
                <i className="bi bi-search"></i></button>
          
                            
        </li>*/}
              <li
                id="pesquisa1"
                className="nav-item"
                style={{ position: "relative", width: "80px" }}
              >
                <div className="container_pesquisa">
                  <input
                    id="pesq1"
                    type="text"
                    value={pesq}
                    onKeyPress={handleKeyPress}
                    onChange={(e) => {
                      handleChangePesq(e.target.value);
                    }}
                    /*onSubmit={(e)=>{processaPesquisa(e);}}*/ placeholder="Pesquisar... "
                    onFocus={(e) => {
                      animatePesquisaIn();
                    }}
                    onBlur={(e) => {
                      animatePesquisaOut();
                    }}
                  />
                  <div
                    id="search1"
                    onClick={(e) => {
                      processaPesquisa(e);
                    }}
                    className="search"
                  >
                    <i
                      className="bi bi-search"
                      style={{
                        fontSize: `${searchVisible === true ? "30" : "22"}px`,
                      }}
                    ></i>
                  </div>
                </div>
              </li>
              <li
                id="pesquisa2"
                className="nav-item container_pesquisasmall"
                style={{}}
              >
                <div className="input-group mb-3">
                  <input
                    id="pesq2"
                    type="text"
                    onKeyPress={handleKeyPress}
                    onChange={(e) => {
                      handleChangePesq(e.target.value);
                    }}
                    value={pesq}
                    className="col-10 form-control"
                    style={{ borderRight: "0" }}
                    placeholder="Pesquisar"
                    aria-label="Pesquisar"
                    aria-describedby="basic-addon2"
                  />
                  <span
                    className="input-group-text "
                    style={{ background: "white", boderLeft: "0px" }}
                    id="basic-addon2"
                  >
                    <i
                      className="bi bi-search"
                      style={{ fontSize: "22px", color: "#212529" }}
                    ></i>
                  </span>
                </div>

                {/*}<input  id="pesq2" type="text"  onKeyPress={handleKeyPress}  onChange={(e)=>{handleChangePesq(e.target.value);}} value={pesq} className="text col-10"/>
                      
            <button  className="btn col-2" onClick={(e)=>{processaPesquisa(e,document.getElementById("pesq2").value);}}><i className="bi bi-search" style={{fontSize: "22px"}}></i></button>
    */}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}
