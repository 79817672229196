import React, { useEffect, useState } from "react";

import ReactPlayer from "react-player";

import "./zona4video.css";
import image_back from "../../../../images/image_back.png";

import api, {
  apiEndpoint,
  siteURL,
  mseErroComunicacao,
} from "../../../../services/api";

import Loading from "../../../../components/Loading";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function Zona4Video() {
  const navigate = useNavigate;
  const [loading, setLoading] = useState(true);
  const [video, setVideo] = useState(null);

  useEffect(() => {
    function youtube_parser(url) {
      var regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      let id = match && match[7].length == 11 ? match[7] : false;
      //alert(id);
      return id;
    }
    async function loadData() {
      //const response =
      await api
        .get("/ligacoes/ligacao/Agrupamento-de-Escolas-de-Monte-da-Ola")
        .then((response) => {
          if (response.data != null) {
            setVideo(youtube_parser(response.data.link));
          }

          setLoading(false);
        })
        .catch((err) => {
          //toast.error(mseErroComunicacao);
          setLoading(false);
          /*setTimeout(function(){
                    navigate("/erro-comunicacao");
                },5000);*/
        });
      //debugger;
      //console.log("LOG1:",response.data.link)
      //setData(response.data);
    }

    loadData();
  }, []);
  if (loading === true) {
    return (
      <section className="noticias-anucios">
        {/*<!-- Noticias e Anuncios -->*/}
        <div
          className="container-fluid padding vid"
          style={{
            backgroundColor: "#c1d837",
            backgroundImage: `url(${image_back})`,
          }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-12 video">
                <Loading />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
  return (
    <section>
      {video !== null && (
        <div
          className="container-fluid padding my vid"
          style={{
            backgroundColor: "#c1d837",
            backgroundImage: `url(${image_back})`,
            backgroundSize: "contain",
            backgroundPosition: "bottom right",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="video col-xs-12">
                <div className="ratio ratio-16x9">
                  <iframe
                    className=""
                    src={`https://www.youtube.com/embed/${video}?autoplay=0&fs=1&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=${siteURL}`}
                    title="Escolas"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
     
    </section>
  );
}
