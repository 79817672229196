import React/*,{useContext}*/ from "react";
import { useNavigate } from "react-router-dom";
import './breadcrumbs.css'

import ScrollButton from 'react-scroll-button'
import RedirectedContext from "../../contexts/global";

export default function BreadCrumbs(props){
    const navigate = useNavigate();

    /*const contexto = useContext(RedirectedContext);*/

    const numElements = props.data.length;
    const elementos=props.data
    const numKeys=numElements
    let key=0;

    function scrollToTop(e){
        window.scrollTo(0, 0)
    }
    function goBackButtom(){
        navigate(-1);
    }
    return(
        <div className="linha-bread-crumbs">
            <ul className="breadcrumbs">
            
                {
                elementos.map((valor,index)=>{  
                    return (           
                        <li key={index}>                
                            {(valor.url!==null && valor.url.length>0 )&& <a href={valor.url}><span>{valor.nome}</span></a>}
                            {valor.url===null && <span>{valor.nome}</span>}
                            { index < numElements-1 &&              
                                <span className="separador">-</span>
                            }
                        </li>          
                        
                    
                    );
                })
                }

            </ul>
            <ScrollButton 
                targetId={"body_id"}
                behavior={'smooth'} 
                buttonBackgroundColor={'#1ABFD5'}
                iconType={'arrow-up'}
                style= {{fontSize: '24px',zIndex:'1000'}}                
                
            />

            
            
            <button id="btn-voltar" type="button" className="btn btn-voltar" onClick={(e)=>{/*contexto.setRedirected(true);*/ navigate(-1);}}>
                    voltar
            </button>
        </div>
    );

    
}


