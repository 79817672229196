import React, { useEffect, useState } from "react";

import "./footer.css";
import { Link, useNavigate } from "react-router-dom";

import logo from "../../images/svg/logo.svg";
import Loading from "../../components/Loading";
import api, { mseErroComunicacao } from "../../services/api";
import logofooter from "../../images/svg/logofooter.svg";
import CookieBanner from "./components/CookieBanner";

import iconCastBoxFM from "../../images/svg/castboxfm_icon.svg";
import iconInstagram from "../../images/svg/insta_icon.svg";
import iconFacebook from "../../images/svg/facebook_icon.svg";
import iconYoutube from "../../images/svg/youtube_icon.svg";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Footer() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [escolas, setEscolas] = useState([]);
  const [freguesias, setFreguesias] = useState([]);
  const [escolaMain, setEscolaMain] = useState({});

  const [linkFacebook, setLinkFacebook] = useState("");
  const [linkYoutube, setLinkYoutube] = useState("");
  const [linkCastBox, setLinkCastBox] = useState("");
  const [linkInstagram, setLinkInstagram] = useState("");

  function extractEmails(text) {
    if (text === null) return "";
    return text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
  }

  useEffect(() => {
    async function loadData() {
      await api
        .get("/escola/all")
        .then((response) => {
          //console.log(resp.data);
          let _escolas = [];
          let _freguesias = [];
          let _escolaMain = {};
          if (response.data.length > 0) {
            setData(response.data);
            for (var i = 0; i < response.data.length; i++) {
              var obj_freg_esc = response.data[i];
              for (var j = 0; j < obj_freg_esc.escolas.length; j++) {
                _escolas.push(obj_freg_esc.escolas[j]);
                if (obj_freg_esc.escolas[j].main === 1) {
                  _escolaMain = obj_freg_esc.escolas[j];
                }
              }
              _freguesias.push(response.data[i].freguesia);
            }
            setData(response.data);
            setEscolas(_escolas);
            setFreguesias(_freguesias);
            setEscolaMain(_escolaMain);
          }
        })
        .catch((erro) => {
          //toast.error(mseErroComunicacao);
          setTimeout(function () {
            navigate("/erro-comunicacao");
          }, 5000);
        });
      //console.log("response",response,typeof(response.data) )
    }

    async function loadData2() {
      await api
        .get("/ligacoes/ligacao/Facebook")
        .then((response) => {
          //console.log("response",response,typeof(response.data) )
          setLinkFacebook(response.data.link);
        })
        .catch((err) => {
          //toast.error(mseErroComunicacao);
          /*setTimeout(function () {
            navigate("/erro-comunicacao");
          }, 5000);*/
        });

      await api
        .get("/ligacoes/ligacao/Youtube")
        .then((response1) => {
          setLinkYoutube(response1.data.link);
        })
        .catch((error) => {
          //toast.error(mseErroComunicacao);
          /*setTimeout(function () {
            navigate("/erro-comunicacao");
          }, 5000);*/
        });

      await api
        .get("/ligacoes/ligacao/CastBox")
        .then((response2) => {
          setLinkCastBox(response2.data.link);
        })
        .catch((error) => {
          //toast.error(mseErroComunicacao);
          /*setTimeout(function () {
            navigate("/erro-comunicacao");
          }, 5000);*/
        });

      await api
        .get("/ligacoes/ligacao/instagram")
        .then((response3) => {
          setLinkInstagram(response3.data.link);
        })
        .catch((error) => {
          //toast.error(mseErroComunicacao);
          /*setTimeout(function () {
            navigate("/erro-comunicacao");
          }, 5000);*/
        });
      //console.log("response",response1,typeof(response1.data) )
    }

    loadData();
    loadData2();
    setLoading(false);
  }, []);

  return (
    <>
      <footer>
        <div
          className="container-fluid fundo justify-content-center pt-5 pb-5"
          style={{ backgroundColor: "#C1D837" }}
        >
          {/*console.log("escolas",escolas)*/}
          {loading === true && <Loading></Loading>}
          {loading === false && (
            <>
              <div className="container">
                <div className="row">
                  <div
                    className="col-12 col-sm-4 foot"
                    style={{ textAlign: "left" }}
                  >
                    {/*<!--style="align-self: center;"-->*/}
                    <a href="/">
                      <img
                        className="card-img"
                        style={{
                          /*maxHeight: "8.5rem",*/ maxWidth: "100%",
                          marginBottom: "2rem",
                        }}
                        src={logofooter}
                        alt="Card image cap"
                      />
                    </a>
                    <p className="info_name">{escolaMain.name}</p>
                    <p>{escolaMain.address}</p>
                    <p>
                      {escolaMain.postalCode} {escolaMain.place}
                    </p>
                    {escolaMain.info &&
                      escolaMain.info.map((info__, index__) => {
                        return <p key={`C${index__}`}>{info__}</p>;
                      })}
                    <div className="foot-links" style={{ width: "100%" }}>
                      <div className="row justify-content-between" style={{}}>
                        {linkFacebook == "" || linkFacebook == null ? (
                          []
                        ) : (
                          <div
                            className="col-2"
                            style={{
                              textAlign: "center",
                              //marginRight: "10px",
                            }}
                          >
                            <a href={linkFacebook} target="_blank">
                              <img src={iconFacebook} width="36"></img>
                            </a>
                          </div>
                        )}
                        {linkInstagram == "" || linkInstagram == null ? (
                          []
                        ) : (
                          <div
                            className="col-2"
                            style={{
                              textAlign: "center",
                              //marginRight: "10px",
                            }}
                          >
                            <a href={linkInstagram} target="_blank">
                              <img src={iconInstagram} width="36"></img>
                            </a>
                          </div>
                        )}
                        {linkYoutube == "" || linkYoutube == null ? (
                          []
                        ) : (
                          <div
                            className="col-2"
                            style={{
                              textAlign: "center",
                             // marginRight: "10px",
                              marginTop:'4px',
                              
                            }}
                          >
                            <a href={linkYoutube} target="_blank">
                              <img src={iconYoutube} height="30" ></img>
                            </a>
                          </div>
                        )}
                        {linkCastBox == "" || linkCastBox == null ? (
                          []
                        ) : (
                          <div
                            className="col-2"
                            style={{
                              textAlign: "center",
                              //marginRight: "10px",
                              marginLeft:'3px'
                            }}
                          >
                            <a href={linkCastBox} target="_blank">
                              <img src={iconCastBoxFM} width="36"></img>
                            </a>
                          </div>
                        )}

                        {/*<!--                <a href="#" className="fas fa-phone-alt"></a><a href="#" className="fab fa-twitter"></a><a href="#" className="fab fa-linkedin-in"></a>     -->*/}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-8 accordion1">
                    <h3>Contactos</h3>

                    <div className="row accordion" id="accordionExample">
                      {/*console.log("escolas",JSON.stringify(escolas))*/}
                      {escolas.map((escola_, index_) => {
                        return (
                          <div className="accordion-item" key={`A${index_}`}>
                            <h2
                              className="accordion-header"
                              id={`heading${index_}`}
                            >
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${index_}`}
                                aria-expanded="false"
                                aria-controls={`collapse${index_}`}
                              >
                                {escola_.name}
                              </button>
                            </h2>
                            <div
                              id={`collapse${index_}`}
                              className="accordion-collapse collapse"
                              aria-labelledby={`heading${index_}`}
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <p>{escola_.address}</p>
                                <p>
                                  {escola_.postalCode} {escola_.place}
                                </p>
                                {escola_.info.map((info__, index__) => {
                                  if (info__ === null) {
                                    return "";
                                  }
                                  if (index__ === 1) {
                                    let emails = extractEmails(info__);
                                    let retornar = "";
                                    let html_emails = "";
                                    let label = "<span><b>Email: </b></span>";
                                    if (emails !== null && emails.length > 0) {
                                      for (var i = 0; i < emails.length; i++) {
                                        html_emails +=
                                          '<a href="mailto:' +
                                          emails[i] +
                                          '">' +
                                          emails[i] +
                                          "</a> | ";
                                      }
                                      html_emails = html_emails.substring(
                                        0,
                                        html_emails.length - 2
                                      );
                                      html_emails =
                                        "<span><b>Email: </b></span>" +
                                        html_emails;

                                      retornar += html_emails;

                                      //return  <p dangerouslySetInnerHTML={{__html:html_emails}}></p>
                                    } else {
                                      html_emails = info__;
                                      retornar +=
                                        `<span>"<b>Email: </b>"</span>` +
                                        html_emails;
                                    }
                                    return (
                                      <p
                                        key={`B${index__}`}
                                        dangerouslySetInnerHTML={{
                                          __html: retornar,
                                        }}
                                      ></p>
                                    );
                                  } else if (index__ === 0) {
                                    //tel
                                    if (info__.length > 0) {
                                      return (
                                        <p key={`B${index__}`}>
                                          <span>
                                            <b>Tel.: </b>
                                          </span>
                                          {info__}
                                        </p>
                                      );
                                    } else {
                                      return "";
                                    }
                                  } else if (index__ === 2) {
                                    //Fax
                                    if (info__.length > 0) {
                                      return (
                                        <p key={`B${index__}`}>
                                          <span>
                                            <b>Fax: </b>
                                          </span>
                                          {info__}
                                        </p>
                                      );
                                    } else {
                                      return "";
                                    }
                                  }

                                  //return(<p key={`B${index__}`}>{info__}</p>);
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="container-fluid fundo2">
          <div
            className="container"
            style={{
              padding: "15px 15px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ width: "100%" }}>
              <div className="row">
                <div className="col-12 col-sm-12  col-md-4 legal">
                  <a href="http://www.jcanao.com/" target="_blank">
                    © 2022 JCanão. All Rights Reserved.
                  </a>
                </div>
                <div className="col-12 col-sm-12 col-md-8 legal alinhamento">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md col-lg-4 offset-lg-4">
                      <Link
                        to="/footer/politica-de-privacidade#privacidade"
                        target="_self"
                      >
                        Política de Privacidade
                      </Link>
                    </div>
                    <div className="col-12 col-sm-12 col-md col-lg-4">
                      <Link
                        to="/footer/politica-de-privacidade#termos"
                        target="_self"
                      >
                        Termos de Serviço
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <CookieBanner />
    </>
  );
}
