import React, { useState } from 'react';

import './listadelinks.css'
export default function ListaDeLinks(props){

    const [lista,setLista]=useState(props.listaLinks)//array;

    return(
        <ul className='lista-de-links'>
            {lista.map((item,index)=>{
                return(
                    <li key={item.id}><a target="_blank" href={item.file_url}>{item.name}</a></li>
                );
            })}
        </ul>
    )
}