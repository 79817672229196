import React from "react";
import { useState,useEffect } from "react/";

import BreadCrumbs from "../../components/BreadCrumbs";

import img_folha_navegacao_dir from '../../images/svg/folha_navegacao_dir.svg';
import img_face_1 from '../../images/orgaos/1.png';
import './orgaosdegestao.css'

import api,{siteURLShare,mseErroComunicacao} from '../../services/api'
import Loading from '../../components/Loading';

import {Helmet} from "react-helmet-async";
import { useLocation, useNavigate } from 'react-router-dom';


import image_coordenadores from '../../images/images_default/coordenador_309x800.jpg'

import profile_default from '../../images/images_default/profile_default.png';
import { toast } from 'react-toastify';

export default function OrgaosDeGestao(){

    const location=useLocation();
    const navigate = useNavigate();
    const [loading,setLoading] = useState(true);
    const [data1,setData1]=useState([]);
    const [data2,setData2]=useState([]);

    useEffect(() => {

        async function loadData() {

            //const response = 
            await api.get('gestao/all').then(response=>{
                            //debugger;
                    //console.log(response.data)
                    
                    setData1(response.data[0]);
                    setData2(response.data[1]);
                    
                    
                    setLoading(false);
            }).catch(err=>{
                //toast.error(mseErroComunicacao);
                setTimeout(function(){
                    navigate("/erro-comunicacao");
                },5000);
            });
        }

        loadData();
        window.scrollTo(0, 0)
    }, []);
    const [breadCrumbsData,setBreadCrumbsData]= useState([
        {
            nome: "Home",
            url: "/"
        },
        {
            nome:"Agrupamento",
            url:null
        },
        {
            nome:"Orgãos de Gestão",
            url: "/orgaos-de-gestao"
        }
    ]);

    return(
<section className ="content">        
    <div className="container detalhe">    
        <BreadCrumbs data={breadCrumbsData}/>
    </div>
    {/* Titulo */}
    <div className="container detalhe">
        <div className="row ligacao justify-content-left">
            <div className="col-12">
                <h1>Orgãos de Gestão</h1>
                <div className="row justify-content-left">
                    <div className="col-2">
                        <hr/>
                    </div>    
                </div>
            </div>
        </div>
    </div>

    {/* corpo detalhe */}
    <div className="container detalhe">
    {loading===true &&(
            <div className="row">    
                <div className="col-12">
                    <Loading></Loading>
                </div>
            </div>    
    )}
    {loading===false &&(
        
        <>
        <div className="row">
            <Helmet>
                <title>{`Agrupamento de Escolas de Monte da Ola ${'>'} Orgãos de Gestão`}</title>
                <meta name="description" content={`Orgãos de Gestão`}/>
                <meta name="keywords" content={`Website do Agrupamento de Escolas de Monte da Ola, Orgãos de Gestão`}/>
                {/*facebook*/}
                <meta name="og:title" content={`Orgãos de Gestão`}/>
                <meta name="og:url" content={siteURLShare+location.pathname} />
                <meta name="og:description" content={`Agrupamento de Escolas de Monte da Ola ${'>'} Orgãos de Gestão`}/>
                <meta name="og:type" content="website"/>
                {/*twitter*/}
                <meta name="twitter:card" content={`Orgãos de Gestão`}/>
                <meta name="twitter:title" content={`Orgãos de Gestão`}/>
                <meta name="twitter:description" content={`Agrupamento de Escolas de Monte da Ola ${'>'} Orgãos de Gestão`}/>
                <meta name="twitter:site" content={siteURLShare+location.pathname}/>
                <meta name="twitter:creator" content="Agrupamento de Escolas de Monte da Ola"/>
            </Helmet>
            <div className="col-12 col-sm-7 col-md-8 col-lg-8">
                {data1.map((item,index)=>{
                    return(
                        <div className="row cartao-face" key={index}>
                            <div className="col-12 conselho-face">
                                <div style={{ display: "block", float: "left", position: "relative", margin: "0px 20px 0px 0px" }}>
                                    
                                {item.image!==null &&(
                                        <img className="img-thumbnail"
                                        src={item.image} alt={item.person} />

                                    )}

                        {item.image === null &&(
                                        <img className="img-thumbnail"
                                        src={profile_default} alt={item.person} />

                                    )}
                                    
                                    <div className="img-overlay">
                                        <img className="img-face" src={img_folha_navegacao_dir} alt="img-face" />
                                    </div>
                                </div>
                                <p>
                                    <span className="conselho-name">{item.position}</span><br />
                                    <span className="conselho-cargo">{item.person}</span>
                                </p>
                            </div>
                        </div>
                    );
                })}
                     
            </div>
            {/*img do item */}
            {data2.image!==null &&(
                <div className="col-12 col-sm-3 col-md-4 col-lg-4">
                    <div className="image-artigo">
                        <img src={data2.image} className="d-block w-100" alt="img-m" />
                    </div>
                </div>
            )}
            {data2.image===null &&(
                <div className="col-12 col-sm-3 col-md-4 col-lg-4">
                    <div className="image-artigo">
                        <img src={image_coordenadores} className="d-block w-100" alt="img-m" />
                    </div>
                </div>
            )}
            
            {/* /img do item */}
        </div>
        
        <div className="row">
            <div className="lista-coordenadores"><h2>Coordenadores de Estabelecimento</h2></div>
        </div>       
        <div className="row">
                <div className="col-12 col-sm-7 col-md-8 col-lg-8 artigo-info lista-coordenadores" dangerouslySetInnerHTML={{ __html:data2.body}}>
            </div>
            
        </div>
        </>
        )}
    </div>
    
</section>
    );
}