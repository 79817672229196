import styled from 'styled-components';

import img_ler_mais from './images/svg/folha_lermais.svg'
import img_ler_mais_hover from './images/svg/folha_lermais2.svg'

import img_ler_mais_big from './images/svg/folha_botao.svg'
import img_ler_mais_big_hover from './images/svg/folha_botao2.svg'

import img_folha_navegacao_dir_azul from './images/svg/folha_navegacao_dir_azul.svg';
import img_folha_navegacao_esq_azul from './images/svg/folha_navegacao_esq_azul.svg';


import setinha_direita from './images/svg/arrow_ir_proj.svg';



export const LinkLerMais = styled.a`
background-image: url(${img_ler_mais});
height: 50px;
min-width:80px;
background-position: bottom;
background-repeat: no-repeat;
background-size: 80px;
font-weight: 600;
padding: 0;
font-size: 20px;
color: #58595B;
margin-top: 0;
margin-bottom: .5rem;
line-height: 1.2;

&:hover {
    background-image: url(${img_ler_mais_hover});
  }
`;

export const ButtonkLerMais = styled.button`

background-image: url(${img_ler_mais_big});
margin-top: 8rem;
margin-bottom: 5rem;
background-position: center;
background-repeat: no-repeat;
height: 130px;
width: 172px;
background-size: 171px;
font-size: 20px !important;
font-family: 'Noto Sans Display', sans-serif;
font-weight: 600 !important;
color: white !important;

&:hover {
    background-image: url(  ${img_ler_mais_big_hover});
  }
`;

export const LinkNext = styled.a`
text-align:right;
background: url(${img_folha_navegacao_dir_azul}) right top no-repeat;
background-size:60px 24px;  

background-size: 50px;
opacity: 1;
padding:0px 60px 0px 0px;

&:hover{
  filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.3));
  opacity: 1;
}
}
`
export const LinkPrev = styled.a`

background: url(${img_folha_navegacao_esq_azul}) left top no-repeat;
background-size:60px 24px;

opacity: 1;
padding:0px 0 0px 60px;


&:hover{
  filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.3));
  opacity: 1;
}
}
`

export const LinkIrParaProjecto= styled.a`


opacity: 1;
padding:5px 10px 5px 20px !important;
border: 2px solid #1ABFD5;
color:#1ABFD5;
text-decoration:none;
border-radius:20px;

&:hover{
  /*filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.3));*/
  opacity: 1;
  color:#1ABFD5;
}

img{
  margin-left:20px;
}
}`

export const BtnPartilhar = styled.button`
width: 40px;
height:40px;
background:transparent;
border:none;
img{
  width:36px;
  height:36px;
}
}
`;