import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import BreadCrumbs from "../../components/BreadCrumbs";

import img_folha_navegacao_dir from "../../images/svg/folha_navegacao_dir.svg";
import img_face_1 from "../../images/orgaos/1.png";

import img_exames from "../../images/avaliacao/exames.jpg";
import "./planoplurianual.css";

import api, { siteURLShare, mseErroComunicacao } from "../../services/api";
import { Helmet } from "react-helmet-async";

import Fancybox from "../../components/FancyBox";

import { toast } from "react-toastify";
export default function PlanoPlurianual() {
  const location = useLocation();
  let navigate = useNavigate();
  const [breadCrumbsData, setBreadCrumbsData] = useState([
    {
      nome: "Home",
      url: "/",
    },
  ]);

  //const [texto,setTexto]=useState("");
  const [tabelas, setTabelas] = useState([]);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  /*const [breadCrumbsData,setBreadCrumbsData]= useState([
        {
            nome: "Home",
            url: "/"
        },
        {
            nome:"TEIP",
            url:null
        },
        {
            nome:"PPM - Plano Plurianual de Melhoria",
            url: "/plano-plurianual"
        }
    ]);*/
  useEffect(() => {
    async function loadData() {
      //const response =
      await api
        .get(`/ppm/main`)
        .then((response) => {
          if (
            typeof response.data == "object" &&
            response.data.status_code === 404
          ) {
            alert("404"); //setError404(true);
          } else if (response.data.length > 0) {
            setTabelas(response.data);
            setBreadCrumbsData([
              {
                nome: "Home",
                url: "/",
              },
              {
                nome: "TEIP",
                url: null,
              },
              {
                nome: "PPM - Plano Plurianual de Melhoria",
                url: "/ppm/plano-plurianual",
              },
            ]);
          }
          if (response.data[0][0] !== null) {
            setData(response.data[0][0].header);
  
          } else if (response.data[1][0] !== null) {
            setData(response.data[1][0].header);
          }
          setLoading(false);
        })
        .catch((err) => {
          //toast.error(mseErroComunicacao);
          setTimeout(function () {
            navigate("/erro-comunicacao");
          }, 5000);
        });
    }

    loadData();

    //console.log(tabelas)

    //window.scrollTo(0, 0)
  }, []);

  /*function setImageCss(event){
        //set mnax-width or set maxheigth consoante o aspect ratio
        let height= event.target.offsetHeight;
        let width=event.target.offsetWidth
        let ap=width/height;
        if(ap<1){
            //mais larga que alta
            event.target.style.maxWidth="30%"
        }else{
            event.target.style.maxHeight="30%"
        }
        //event.target.style.maxWidth="35%"
    };*/
  return (
    <section className="content">
      <div className="container detalhe">
        <BreadCrumbs data={breadCrumbsData} />
      </div>
      {/* Titulo */}
      <div className="container detalhe">
        <div className="row ligacao justify-content-left">
          <div className="col-12">
            <h1>PPM - Plano Plurianual de Melhoria</h1>
            <div className="row justify-content-left">
              <div className="col-2">
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*<!--Tabela 1 -->*/}

      <section>
        <div className="container detalhe">
          {loading === false && tabelas.length > 0 && data !== null && (
            <Helmet>
              <title>{`Agrupamento de Escolas de Monte da Ola ${">"} PPM - Plano Plurianual de Melhoria`}</title>
              <meta
                name="description"
                content={data.replace(/<[^>]+>/g, "").substring(0, 200)}
              />
              <meta
                name="keywords"
                content={`Website do Agrupamento de Escolas de Monte da Ola, PPM - Plano Plurianual de Melhoria`}
              />
              {/*facebook*/}
              <meta
                name="og:title"
                content={`PPM - Plano Plurianual de Melhoria`}
              />
              <meta name="og:url" content={siteURLShare + location.pathname} />
              <meta
                name="og:description"
                content={data.replace(/<[^>]+>/g, "").substring(0, 200)}
              />
              <meta name="og:type" content="website" />
              {/*twitter*/}
              <meta
                name="twitter:card"
                content={`PPM - Plano Plurianual de Melhoria`}
              />
              <meta
                name="twitter:title"
                content={`PPM - Plano Plurianual de Melhoria`}
              />
              <meta
                name="twitter:description"
                content={data.replace(/<[^>]+>/g, "").substring(0, 200)}
              />
              <meta
                name="twitter:site"
                content={siteURLShare + location.pathname}
              />
              <meta
                name="twitter:creator"
                content="Agrupamento de Escolas de Monte da Ola"
              />
            </Helmet>
          )}
          {loading === false && (
            <>
              {tabelas.map((item, index) => {
                //debugger;
                if (item[0] !== null && item[0] !== "") {
                  //console.log("ANALISAR ITEM:", item, index);
                  let linkA = "";
                  if (item[0].file !== null && item[0].file.length > 0) {
                    linkA =
                      '<a href="' +
                      item[0].file +
                      '" target="_blank">Ficha de projeto</a>';
                  }
                  return (
                    <div key={index}>
                      {/* BLOCO REPETIR TABELA */}
                      {item[0].header !== null && item[0].header.length > 0 && (
                        <div className="row justify-content-center">
                          <div
                            className="col-12 col-sm-12 col-md-12 col-lg-12 artigo-info plano-plurianual"
                            dangerouslySetInnerHTML={{ __html: item[0].header }}
                          ></div>
                        </div>
                      )}

                      <div
                        className="row tabela-titulo"
                        style={{ marginTop: "4rem" }}
                      >
                        <div className="col-12 mt-10">
                          <h3>
                            {item[0].title}
                            <span
                              style={{
                                color: "#1ABFD5",
                                fontFamily: "'Andada Pro', serif",
                              }}
                              dangerouslySetInnerHTML={{ __html: linkA }}
                            ></span>
                          </h3>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 tabela2">
                          {item[1].map((item1, index1) => {
                            let html_body = "";
                            //{/*if(item1.image!==null && item1.image.length>0){
                            //
                            //    html_body+='<img onload={alerta()} src="'+item1.image+'" class="img-fluid" style="float:right;/*max-width:200px;*/" />'+item1.body
                            //}else{
                            //    html_body=item1.body;
                            //}*/

                            if (item1 !== null && item1 !== "") {
                              let imagens = [
                                {
                                  url: item1.image,
                                  title: "image title 1",
                                },
                              ];

                              return (
                                <div key={index1}>
                                  <table className="table2">
                                    <thead>
                                      <tr>
                                        <th className="titulo-plurianual">
                                          {item1.type ===
                                          "projetocofinanciado" ? (
                                            item1.projectRecord !== null &&
                                            item1.projectRecord.length > 0 &&
                                            item1.projectRecordState ===
                                              "Ativo" ? (
                                              <a
                                                target="_blank"
                                                href={item1.projectRecord}
                                              >
                                                AÇÃO {item1.order} -
                                                {item1.action}
                                              </a>
                                            ) : (
                                              <>
                                                AÇÃO {item1.order} -
                                                {item1.action}
                                              </>
                                            )
                                          ) : (
                                            []
                                          )}

                                          {item1.type === "ppm" ? (
                                            item1.projectRecord !== null &&
                                            item1.projectRecord.length > 0 &&
                                            item1.projectRecordState ===
                                              "Ativo" ? (
                                              <a
                                                target="_blank"
                                                href={item1.projectRecord}
                                              >
                                                {item1.order} -{item1.action}
                                              </a>
                                            ) : (
                                              <>
                                                {item1.order} -{item1.action}
                                              </>
                                            )
                                          ) : (
                                            []
                                          )}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        {/*} <td scope="row" dangerouslySetInnerHTML={{__html:html_body}}></td>*/}
                                        <td scope="row">
                                          <div className="row">
                                            <div
                                              className="col-8"
                                              dangerouslySetInnerHTML={{
                                                __html: item1.body,
                                              }}
                                            ></div>
                                            <div className="col-4">
                                              {item1.image !== null && (
                                                <Fancybox
                                                  options={{ infinite: false }}
                                                >
                                                  <img
                                                    data-fancybox={`gallery${index1}`}
                                                    data-src={item1.image}
                                                    src={item1.image}
                                                    data-title={item1.title}
                                                    className="img-fluid"
                                                    style={{
                                                      cursor: "zoom-in",
                                                      float: "right",
                                                      marginLeft: "15px",
                                                      maxWidth: "200px",
                                                    }}
                                                    alt={item1.title}
                                                  />
                                                </Fancybox>
                                              )}
                                              {/*<img src={item1.image} 
                                                                    className="img-fluid" style={{float:"right",marginLeft:"15px",maxWidth:"200px"}} 
                                                                    title="Image Title" /> */}
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <div className="row rodape">
                                    <div className="col-12">
                                      <p className="tresponsavel">
                                        <span>
                                          RESPONSÁVEL:&nbsp;{item1.author}
                                        </span>
                                      </p>
                                      <hr className="borderBottomResponsavel"></hr>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </>
          )}

          <div
            className="col-12 col-sm-12 justify-content-center"
            style={{ display: "flex", marginTop: "4rem" }}
          >
            <button
              id="btn-voltar1"
              type="button"
              style={{ display: "flex" }}
              className="btn btn-voltar"
              onClick={(e) => {
                navigate(-1);
              }}
            >
              Voltar
            </button>
          </div>
        </div>
        {/* <ImagerDisplay z-index="2000" />*/}
      </section>
    </section>
  );
}
